import { Col, PageWrapper, Row } from '@qonsoll/react-design'

import { ContentSimpleView } from 'domains/Content/components'
import { HeaderBreadcrumbs } from 'components'
import { useEvent } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useTranslations } from '@qonsoll/translation'
import { useUser } from 'domains/User/context'

const GDPRPage = () => {
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()

  const onBack = useEvent(() => history.goBack())

  const headingProps = {
    title: t('Cookie Policy'),
    marginTop: 4,
    marginBottom: 4
  }

  const { emailVerified: isAuthenticated } = useUser()

  return (
    <PageWrapper
      headingProps={headingProps}
      onBack={isAuthenticated && onBack}
      breadcrumbs={isAuthenticated && <HeaderBreadcrumbs />}>
      <Row noGutters>
        <Col cw={12}>
          <ContentSimpleView isAuthenticated={!!isAuthenticated} />
        </Col>
      </Row>
    </PageWrapper>
  )
}
export default GDPRPage
