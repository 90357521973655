import { Box, Button, Col, Row } from '@qonsoll/react-design'
import {
  FormStyled,
  StyledFilter,
  filterBackground,
  filterMaxHeight
} from './NewsTipFilter.styles'
import { useInitializeNewsTipFilter, useNewsTipFilterActions } from './hooks'

import DateFilter from './components/DateFilter'
import { Form } from 'antd'
import PropTypes from 'prop-types'
// Import error
import TypeFilter from './components/TypeFilter'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const NewsTipFilter = (props) => {
  const { filterVisibility, filterData, setFilterData } = props

  const [disabledDate, setDisabledData] = useState({ start: null, end: null })
  /* Getting translations instance */
  const { t } = useTranslations()
  const [form] = Form.useForm()

  useInitializeNewsTipFilter({ form, filterData, setDisabledData })

  const { onFinish, onReset } = useNewsTipFilterActions({
    form,
    filterData,
    setFilterData,
    setDisabledData
  })

  return filterVisibility ? (
    <FormStyled onFinish={onFinish} form={form}>
      <StyledFilter maxHeight={filterMaxHeight} background={filterBackground}>
        <Row width="100%" v="center" h="center">
          <DateFilter
            disabledDate={disabledDate}
            setDisabledData={setDisabledData}
          />
          <Col>
            <TypeFilter placeholder={t('User Type')} />
          </Col>
          <Col>
            <Box display="flex" gap={'16px'}>
              <Button
                block
                key="reset"
                type="ghost"
                htmlType="button"
                onClick={onReset}>
                {t('Reset')}
              </Button>

              <Button block key="apply" type="primary" htmlType="submit">
                {t('Apply')}
              </Button>
            </Box>
          </Col>
        </Row>
      </StyledFilter>
    </FormStyled>
  ) : null
}

NewsTipFilter.propTypes = {
  filterVisibility: PropTypes.bool,
  setFilterVisibility: PropTypes.func,
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default NewsTipFilter
