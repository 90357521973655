import { Box, Button, Col, Row } from '@qonsoll/react-design'
import {
  FormStyled,
  StyledFilter,
  buttonProps,
  filterBackground,
  filterMaxHeight
} from './UserNewsTipsFilter.styles'
import {
  useInitializeUserNewsTipsFilter,
  useUserNewsTipsFilterActions
} from './hooks'

import { Form } from 'antd'
import { NewsTipsStatusFilter } from './components'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const UserNewsTipsFilter = (props) => {
  const { filterVisibility, filterData, setFilterData } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  const [form] = Form.useForm()

  useInitializeUserNewsTipsFilter({ filterData, form })

  const { onFinish, onReset } = useUserNewsTipsFilterActions({
    form,
    filterData,
    setFilterData
  })

  return filterVisibility ? (
    <FormStyled onFinish={onFinish} form={form}>
      <StyledFilter maxHeight={filterMaxHeight} background={filterBackground}>
        <Row width="100%" v="center" justifyContent="flex-end">
          <Col cw={2}>
            <NewsTipsStatusFilter
              filterData={filterData}
              setFilterData={setFilterData}
            />
          </Col>
          <Col cw="auto">
            <Box display="flex" gap={'16px'}>
              <Button
                {...buttonProps?.onReset}
                htmlType="button"
                onClick={onReset}>
                {t('Reset')}
              </Button>

              <Button {...buttonProps?.onFinish} htmlType="submit">
                {t('Apply')}
              </Button>
            </Box>
          </Col>
        </Row>
      </StyledFilter>
    </FormStyled>
  ) : null
}

UserNewsTipsFilter.propTypes = {
  filterVisibility: PropTypes.bool,
  setFilterVisibility: PropTypes.func,
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default UserNewsTipsFilter
