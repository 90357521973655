import { COLLECTIONS, STATUSES } from '__constants__'
import { collection, query, where } from 'firebase/firestore'

import firestore from 'services/firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'

/**
 * It returns an array of values that are used to render the reject reasons
 * @param props - An object that contains the query parameters.
 * @returns An array of values.
 */
const useGetRejectReasons = (newspaperId) => {
  const queryItems =
    newspaperId &&
    query(
      collection(firestore, COLLECTIONS.REJECT_REASONS),
      where('newspaperId', '==', newspaperId),
      where('status', '==', STATUSES.REJECT_REASON_STATUSES.ACTIVE)
    )

  return useCollectionData(queryItems)
}

export default useGetRejectReasons
