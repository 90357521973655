import { Button, Col, Container, Row, Text, Title } from '@qonsoll/react-design'
import {
  CommentsCard,
  ContentCard,
  GeneralDetailsCard,
  StyledImg,
  StyledVideo
} from './NewsTipAdvancedView.styles'
import {
  NewsTipActionModal,
  NewsTipLocation,
  NewsTipMediaList,
  NewsTipNewsTipStatus
} from 'domains/NewsTip/components'

import { Empty } from 'components'
import { NewsTipCommentsList } from 'domains/NewsTipComment/components'
import { Popconfirm } from 'antd'
import PropTypes from 'prop-types'
import { STATUSES } from '__constants__'
import { TagList } from 'domains/Tag/components'
import { createFullUserName, getFormattedDateAndTime } from 'helpers'
import { useNewsTipBaseActions } from 'domains/NewsTip/hooks'
import { usePhoneNumber } from 'hooks'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const NewsTipAdvancedView = (props) => {
  const { newsTip, author } = props

  const { guestFirstName, guestLastName, guestPhoneNumber } = newsTip
  const newsTipId = newsTip?._id

  /* Getting translations instance */
  const { t } = useTranslations()
  const { transformPhoneNumber } = usePhoneNumber()
  const actions = useNewsTipBaseActions({ newsTipId })

  /* Getting formatted date and time */
  const dateAndTime = getFormattedDateAndTime(newsTip?._createdAt?.seconds)

  const computedAuthor = author?.fullName
    ? author?.fullName
    : createFullUserName(guestFirstName, guestLastName)

  const computedPhoneNumber = author?.phone || guestPhoneNumber
  // Form map with title details (objects contain title, data or component)
  const generalDetails = [
    {
      title: 'Sno',
      data: newsTip?.sno
    },
    {
      title: 'Status',
      component: (
        <NewsTipNewsTipStatus
          emptyProps={{ withIllustration: false, withCreate: false }}
          newsTip={newsTip}
          noDataTextProps={{ textAlign: 'right' }}
        />
      )
    },
    {
      title: 'Reject reason',
      data: newsTip?.rejectReason
    },
    {
      title: 'Posted on',
      data: dateAndTime
    },

    {
      title: 'Location',
      data: newsTip?.locationId && <NewsTipLocation newsTip={newsTip} />
    },

    {
      title: 'Points',
      data: newsTip?.points
    },

    {
      title: 'Posted by',
      data: computedAuthor
    },
    {
      title: 'Email',
      data: author?.email
    },
    {
      title: 'Phone',
      data: transformPhoneNumber(computedPhoneNumber)
    },
    {
      title: 'Tags',
      component: <TagList tags={newsTip.tags} />
    }
  ].filter(({ data, component }) => data || component)

  const computedCoverPhoto = newsTip?.coverPhotoUrl || newsTip?.photoUrls?.[0]

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalAction, setModalAction] = useState(null)

  const handleAction = (action) => {
    if (
      action === STATUSES.NEWS_TIP_STATUSES.APPROVED &&
      !!newsTip?.guestPhoneNumber &&
      !newsTip?._createdBy
    ) {
      // no need to await
      actions?.onUpdateStatus({ status: STATUSES.NEWS_TIP_STATUSES.APPROVED })
      actions?.onBack()
      return
    }
    setIsModalOpen(true)
    setModalAction(action)
  }

  const handleDelete = () => {
    actions?.onDelete()
    actions?.onBack()
  }

  const statuses = {
    pending: newsTip?.status === STATUSES.NEWS_TIP_STATUSES.PENDING,
    rejected: newsTip?.status === STATUSES.NEWS_TIP_STATUSES.REJECTED,
    approved: newsTip?.status === STATUSES.NEWS_TIP_STATUSES.APPROVED,
    draft: newsTip?.status === STATUSES.NEWS_TIP_STATUSES.DRAFT
  }

  const tipsDataBorderBottom = (index) =>
    index !== generalDetails?.length - 1 && 'var(--ql-dashed-border)'

  const isShowApproveButton = !statuses.approved && !statuses.draft
  const isShowRejectButton =
    newsTip?.status === STATUSES.NEWS_TIP_STATUSES.PENDING

  return (
    <Container>
      <Row mx="var(--ql-grid-gutter-negative)">
        <Col cw={4}>
          <GeneralDetailsCard>
            <Row noGutters mb={2} v="center">
              <Col h="left">
                {computedCoverPhoto ? (
                  <StyledImg src={computedCoverPhoto} />
                ) : newsTip?.videoUrl ? (
                  //Added video tag without controls for preview image on tip, for poster image enough video metadata
                  <StyledVideo src={newsTip?.videoUrl} preload="metadata" />
                ) : (
                  <Empty message={t('No image provided')} />
                )}
              </Col>
            </Row>
            <Row noGutters mb={2} v="center">
              <Col h="left">
                <Title level={5}>{newsTip?.title}</Title>
              </Col>
            </Row>
            {generalDetails?.map(({ title, data, component }, index) => (
              <Row
                borderBottom={tipsDataBorderBottom(index)}
                noGutters
                v="center"
                py={1}
                key={title}>
                <Col cw="auto" h="left" v="center">
                  <Text variant="overline" type="secondary">
                    {t(title)}:
                  </Text>
                </Col>
                <Col h="right">
                  {component || <Text textAlign="right">{data}</Text>}
                </Col>
              </Row>
            ))}
          </GeneralDetailsCard>
          <Row noGutters>
            <Col
              cw={12}
              flexDirection="row"
              justifyContent="center"
              my={3}
              style={{ gap: '16px' }}>
              <>
                {isShowApproveButton ? (
                  <Button
                    type="primary"
                    onClick={() =>
                      handleAction(STATUSES.NEWS_TIP_STATUSES.APPROVED)
                    }>
                    {t('Approve')}
                  </Button>
                ) : null}

                {isShowRejectButton ? (
                  <Button
                    danger
                    onClick={() =>
                      handleAction(STATUSES.NEWS_TIP_STATUSES.REJECTED)
                    }>
                    {t('Reject')}
                  </Button>
                ) : null}

                <Popconfirm
                  title={t('Are you sure want to remove tip?')}
                  onConfirm={handleDelete}
                  okText={t('Yes')}
                  cancelText={t('No')}>
                  <Button type="ghost">{t('Delete')}</Button>
                </Popconfirm>
              </>
            </Col>
          </Row>
        </Col>
        <Col cw={8}>
          <Row noGutters>
            {newsTip?.isAnonymous && !!newsTip?._createdBy && (
              <Col cw={12} mb={3}>
                <ContentCard>
                  <Text color="var(--ql-color-danger)" fontWeight={600}>
                    {t(
                      'Pay attention! This user wants to be anonymous and should not be credited on media'
                    )}
                  </Text>
                </ContentCard>
              </Col>
            )}
            {newsTip?.guestPhoneNumber ? (
              <Col cw={12} mb={3}>
                <ContentCard>
                  <Text color="var(--ql-color-danger)" fontWeight={600}>
                    {t('The Tip is from guest !')}
                  </Text>
                </ContentCard>
              </Col>
            ) : null}
            <Col cw={12} mb={3}>
              <ContentCard>
                <Row noGutters>
                  <Col cw={12} mb={2}>
                    <Text variant="overline" type="secondary">
                      {t('Content')}
                    </Text>
                  </Col>
                  <Col cw={12}>
                    {newsTip?.content ? (
                      <Text>{newsTip?.content}</Text>
                    ) : (
                      <Text type="secondary">{t('There is no content')}</Text>
                    )}
                  </Col>
                </Row>
              </ContentCard>
            </Col>
            <Col cw={12} mb={3}>
              <NewsTipMediaList
                photoUrls={newsTip?.photoUrls}
                videoUrl={newsTip?.videoUrl}
              />
            </Col>
            <Col cw={12}>
              <CommentsCard>
                <Text variant="overline" type="secondary" mb={3}>
                  {t('Comments')}
                </Text>

                <NewsTipCommentsList newsTipId={newsTip?._id} author={author} />
              </CommentsCard>
            </Col>
          </Row>
        </Col>
      </Row>
      <NewsTipActionModal
        newsTip={newsTip}
        modalAction={modalAction}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setModalAction={setModalAction}
      />
    </Container>
  )
}

NewsTipAdvancedView.propTypes = {
  newsTip: PropTypes.shape({
    _createdAt: PropTypes.object,
    _createdBy: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    videoUrl: PropTypes.string,
    photoUrls: PropTypes.array,
    title: PropTypes.string,
    content: PropTypes.string,
    tags: PropTypes.array,
    isAnonymous: PropTypes.bool,
    coverPhotoUrl: PropTypes.string,
    locationId: PropTypes.string,
    sno: PropTypes.number,
    points: PropTypes.number,
    status: PropTypes.string,
    rejectReason: PropTypes.string,
    _id: PropTypes.string,
    guestFirstName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    guestLastName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    guestPhoneNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    isGuestMode: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  }),
  author: PropTypes.shape({
    fullName: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string
  })
}

export default NewsTipAdvancedView
