import {
  AppstoreOutlined,
  CopyOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  SortAscendingOutlined,
  UnorderedListOutlined
} from '@ant-design/icons'
import { BadgeStyled, StyledFilterBox } from './PageActions.styles'
import { Box, Button, Divider, Input, Popover } from '@qonsoll/react-design'
import { Popconfirm, Tooltip, Button as AntdButton } from 'antd'

import PropTypes from 'prop-types'
import { useSearchFilter } from 'hooks'
import { useTranslations } from 'contexts/Translation'

/**
 * It renders a set of buttons that are used to perform actions on a list of items
 * @param filterButtonText - The text that will be displayed when the user hovers over the filter
 * button
 * @param onFilterButtonClick - function that will be called when the filter button is clicked
 * @param filterButtonIcon - Icon to be displayed on the filter button
 * @param listView - boolean
 * @param setListView - a function that sets the listView state
 * @param onCreate - function to be called when the create button is clicked
 * @param onWizard - function to be called when the wizard button is clicked
 * @param onEdit - Function to be called when the edit button is clicked
 * @param onCopy - Function to be called when the copy button is clicked
 * @param actions - an object with the following properties:
 * @param createText - The text to be displayed on the create button
 * @param filterModalView - props to show filter as modal - default as block element
 * @returns A function that returns a JSX element
 */
const PageActions = (props) => {
  const {
    filterButtonText,
    onFilterButtonClick,
    filterButtonIcon,
    listView,
    setListView,
    onCreate,
    onWizard,
    onEdit,
    onBlock,
    isBlocked,
    onDelete,
    onCopy,
    onDownload,
    actions,
    createText,
    sortMenu,
    circle,
    filterVisibility,
    filterMenu,
    filterData,
    searchPlaceholder = 'Search',
    collection,
    setCollection,
    searchField,
    editTooltip,
    editButtonProps
  } = props
  /* Getting translations instance */
  const { t } = useTranslations()

  const listViewHandleClick = () => setListView(!listView)
  const buttonShape = circle ? 'circle' : 'unset'
  const buttonBoxShadow = circle ? 'var(--btn-circle-box-shadow)' : 'unset'

  const [handleSearch] = useSearchFilter({
    collection,
    setCollection,
    searchField
  })

  const filterCount = filterData?.where?.length || 0

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {actions?.search && (
          <Box flex="1">
            <Input
              placeholder={t(searchPlaceholder)}
              onChange={handleSearch}
              onKeyPress={handleSearch}
              suffix={<SearchOutlined />}
              width="100%"
              maxWidth={[200, 300, 400, 500, 600]}
            />
          </Box>
        )}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ gap: '8px' }}>
          {actions?.edit &&
            (editTooltip ? (
              <Tooltip title={editTooltip}>
                <AntdButton
                  icon={<EditOutlined />}
                  onClick={onEdit}
                  {...editButtonProps}>
                  {t('Edit')}
                </AntdButton>
              </Tooltip>
            ) : (
              <Button
                icon={<EditOutlined />}
                onClick={onEdit}
                {...editButtonProps}>
                {t('Edit')}
              </Button>
            ))}

          {actions?.block && (
            <Button type="primary" onClick={onBlock}>
              {t(isBlocked ? 'Unblock' : 'Block')}
            </Button>
          )}
          {actions?.delete && (
            <Popconfirm
              okButtonProps={{ danger: true }}
              title={t('Are you sure you want to remove this item?')}
              onConfirm={onDelete}
              okText={t('Yes')}
              cancelText={t('No')}>
              <Button type="ghost">{t('Delete')}</Button>
            </Popconfirm>
          )}

          {actions?.copy && (
            <Button icon={<CopyOutlined />} onClick={onCopy}>
              {t('Copy')}
            </Button>
          )}
          {actions?.download && (
            <Button icon={<CopyOutlined />} onClick={onDownload}>
              {t('Download')}
            </Button>
          )}
          {actions?.sort && (
            <Tooltip title={t('Open sort menu')}>
              <Popover
                content={sortMenu}
                trigger="click"
                placement="bottomRight">
                <Button
                  shape="circle"
                  type="primary"
                  icon={<SortAscendingOutlined />}
                />
              </Popover>
            </Tooltip>
          )}
          {actions?.listView && (
            <Tooltip title={t('Change list view')} placement="topRight">
              <Button
                shape="circle"
                type="primary"
                onClick={listViewHandleClick}
                icon={
                  listView ? <AppstoreOutlined /> : <UnorderedListOutlined />
                }
              />
            </Tooltip>
          )}
          {actions?.wizard && (
            <>
              <Divider
                mx={3}
                height="24px"
                alignSelf="center"
                type="vertical"
              />
              <Button type="primary" icon={<PlusOutlined />} onClick={onWizard}>
                {createText}
              </Button>
            </>
          )}
          {actions?.create && (
            <>
              <Button
                shape={buttonShape}
                type="primary"
                boxShadow={buttonBoxShadow}
                icon={<PlusOutlined />}
                onClick={onCreate}>
                {createText}
              </Button>
            </>
          )}
          {actions?.allExport && (
            <Button type="primary">{t('All Export CSV')}</Button>
          )}
          {actions?.export && <Button type="primary">{t('Export CSV')}</Button>}
          {actions?.filter && (
            <Tooltip title={filterButtonText}>
              <BadgeStyled count={filterCount}>
                <Button
                  shape="circle"
                  type="primary"
                  onClick={onFilterButtonClick}
                  icon={filterButtonIcon}
                  boxShadow={'var(--btn-circle-box-shadow)'}
                />
              </BadgeStyled>
            </Tooltip>
          )}
        </Box>
      </Box>
      {actions?.filter && (
        <StyledFilterBox filterVisibility={filterVisibility}>
          {filterMenu}
        </StyledFilterBox>
      )}
    </>
  )
}

PageActions.propTypes = {
  actions: PropTypes.shape({
    create: PropTypes.bool,
    edit: PropTypes.bool,
    block: PropTypes.bool,
    delete: PropTypes.bool,
    copy: PropTypes.bool,
    filter: PropTypes.bool,
    listView: PropTypes.bool,
    sort: PropTypes.bool,
    wizard: PropTypes.bool,
    search: PropTypes.bool,
    download: PropTypes.bool
  }),
  isBlocked: PropTypes.bool,
  filterModalView: PropTypes.bool,
  filterVisibility: PropTypes.bool,
  filterData: PropTypes.object,
  searchPlaceholder: PropTypes.string,
  setFilterVisibility: PropTypes.func,
  filterMenu: PropTypes.node,
  circle: PropTypes.bool,
  sortMenu: PropTypes.node,
  createText: PropTypes.string,
  filterButtonIcon: PropTypes.any,
  filterButtonText: PropTypes.string,
  listView: PropTypes.bool,
  onCreate: PropTypes.func,
  onEdit: PropTypes.func,
  onBlock: PropTypes.func,
  onDelete: PropTypes.func,
  onCopy: PropTypes.func,
  onFilterButtonClick: PropTypes.func,
  onWizard: PropTypes.func,
  setListView: PropTypes.func,
  collection: PropTypes.array,
  setCollection: PropTypes.func,
  searchField: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  editTooltip: PropTypes.func,
  onDownload: PropTypes.func,
  editButtonProps: PropTypes.object
}

export default PageActions
