import PropTypes from 'prop-types'
import { StyledTable } from 'components'
import { useGetNewsTipTableColumns } from 'domains/NewsTip/hooks'
import { useState } from 'react'

const NewsTipTable = (props) => {
  const { pending, rejected } = props

  /* Pagination params for table */
  const [tableParams, setTableParams] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
    showSizeChanger: true
  })

  /* Getting table columns */
  const columns = useGetNewsTipTableColumns(pending, rejected)

  /* Handle changes for table */
  const handleTableChange = (pagination) => setTableParams(pagination)

  return (
    <StyledTable
      maxHeight={400}
      {...props}
      rowKey="_id"
      columns={columns}
      pagination={tableParams}
      onChange={handleTableChange}
    />
  )
}

NewsTipTable.propTypes = {
  pending: PropTypes.bool,
  rejected: PropTypes.bool
}

export default NewsTipTable
