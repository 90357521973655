import { Form } from 'antd'
import { Option } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { STATUSES } from '__constants__'
import { StyledSelect } from '../UserNewsTipsFilter.styles'
import { capitalize } from 'helpers'
import { useTranslations } from '@qonsoll/translation'

const NewsTipsStatusFilter = (props) => {
  const { placeholder } = props
  const { t } = useTranslations()

  const newsTipStatuses = Object.values(STATUSES.NEWS_TIP_STATUSES)?.map(
    (newsTip) => <Option value={newsTip}>{capitalize(newsTip)}</Option>
  )
  newsTipStatuses?.push(<Option value={false}>{t(`None`)}</Option>)

  return (
    <Form.Item name="status">
      <StyledSelect defaultValue={false} placeholder={placeholder}>
        {newsTipStatuses}
      </StyledSelect>
    </Form.Item>
  )
}

NewsTipsStatusFilter.propTypes = {
  placeholder: PropTypes.string
}

export default NewsTipsStatusFilter
