import {
  CURRENT_APP,
  DEFAULT_LANGUAGE,
  LANGUAGES
} from 'domains/Translation/__constants__'
import Provider, { TranslationContext } from '@qonsoll/translation'

import { Box } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import firebase from 'firebase/compat/app'
import { useMemo } from 'react'
import { LS, ENVIRONMENTS } from '__constants__'
import {
  useGetLanguages,
  useGetTranslationLoading
} from 'domains/Translation/hooks'
import { ref, update, onValue } from 'firebase/database'
import { database } from 'services/firebase'

const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT

const TranslationsProvider = (props) => {
  const { children } = props

  const [languages, languagesLoading] = useGetLanguages()
  const [translationLoadingConfig, translationLoadingConfigLoading] =
    useGetTranslationLoading()
  const db = useMemo(() => firebase.database(), [])
  const loadingText = useMemo(
    () =>
      translationLoadingConfigLoading
        ? 'Loading data for translation'
        : translationLoadingConfig?.[localStorage.getItem(LS.LANGUAGE)],
    [translationLoadingConfig, translationLoadingConfigLoading]
  )

  const initialLanguages = useMemo(
    () => (languagesLoading ? LANGUAGES : languages),
    [languages, languagesLoading]
  )

  // Set saveNewAutomatically = true if current env is dev, else - set saveNewAutomatically = false
  const saveNewAutomatically =
    REACT_APP_ENVIRONMENT === ENVIRONMENTS.DEVELOPMENT

  // Function is used to read translation, may has listener
  const handleRead = ({ ref: path, setTranslations, options }) => {
    // try/catch block is over handleRead function
    onValue(
      ref(database, path),
      (snapshot) => setTranslations(snapshot?.val() || {}),
      options
    )
  }

  // Function is used to save translation or errors
  const handleWrite = async ({ ref: reference, value }) => {
    // try/catch block is over handleWrite function
    await update(ref(database, reference), value)
  }

  return !languagesLoading && !translationLoadingConfigLoading ? (
    <Provider
      languages={initialLanguages}
      defaultLanguage={DEFAULT_LANGUAGE}
      currentApp={CURRENT_APP}
      db={db}
      onRead={handleRead}
      onWrite={handleWrite}
      saveNewAutomatically={saveNewAutomatically}>
      <TranslationContext.Consumer>
        {({ loaded }) => (
          <Box width="inherit" height="inherit">
            {!loaded && (
              <Box
                position="fixed"
                bg="white"
                width="100vw"
                height="100vh"
                zIndex={1000}>
                <Spinner text={loadingText} />
              </Box>
            )}
            {children}
          </Box>
        )}
      </TranslationContext.Consumer>
    </Provider>
  ) : (
    <Box position="fixed" bg="white" width="100vw" height="100vh" zIndex={1000}>
      <Spinner text={loadingText} />
    </Box>
  )
}

TranslationsProvider.propTypes = {
  children: PropTypes.any
}

export default TranslationsProvider
