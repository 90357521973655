import { STATUSES } from '__constants__'

/**
 * It returns an array of values that are used to render the newsTipStatuses
 * @param props - An object that contains the query parameters.
 * @returns An array of values.
 */
const useGetNewsTipStatuses = (props) => {
  // TODO remove this code after 10.01.2023, if there will not
  // be necessity to make dynamic statuses types
  /* Query for getting collection reference */
  // const query = useMemo(
  //   () => ({ ref: COLLECTIONS.NEWS_TIP_STATUSES, ...props }),
  //   [props]
  // )
  // /* Getting collection data */
  // const [value, loading, error, next, loadingMore, loadMoreAvailable] =
  //   useCollection(query)
  // return [value, loading, error, next, loadingMore, loadMoreAvailable]
  return [Object.keys(STATUSES.NEWS_TIP_STATUSES)]
}

export default useGetNewsTipStatuses
