import 'firebase/auth'

import { Box } from '@qonsoll/react-design'
import { useHistory, useLocation } from 'react-router-dom'

import PATHS from './paths'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import firebase from 'firebase/compat/app'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useEffect } from 'react'
import { useHandleError } from 'hooks'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const RoutesRedirect = ({ children }) => {
  const { t } = useTranslations()
  const history = useHistory()
  const location = useLocation()
  const handleError = useHandleError()
  const [user, loading, error] = useAuthState(firebase.auth())
  const { _isSessionFetched, isTemporaryPasswordResolved } = useUser()

  const unauthenticatedPaths = Object.values(PATHS.UNAUTHENTICATED)
  const servicePaths = Object.values(PATHS.SERVICE)

  // Making decision how to redirect
  useEffect(() => {
    /* This code is checking if the current path is in the unauthenticatedPaths array. */
    const isUnauthenticatedPath = unauthenticatedPaths.includes(
      location.pathname
    )

    const isServicePath = servicePaths.includes(location.pathname)

    /* If the user is logged in, and the user's email is verified, then the user is logged in. */
    const isEmailNotVerified = user && !user.emailVerified
    const isLoggedIn =
      isUnauthenticatedPath &&
      !isServicePath &&
      user &&
      !loading &&
      !isEmailNotVerified
    const isLoggedOut =
      !(isUnauthenticatedPath || isServicePath) && !user && !loading

    /* Check if temporary password is resolved */
    user &&
      !loading &&
      _isSessionFetched &&
      !isTemporaryPasswordResolved &&
      history.push(PATHS.AUTHENTICATED.RESET_PASSWORD)

    /* If user is logged in and doesn't have selected location redirect to Profile edit 
       also allow redirect to reset password */
    /* If the user is logged in redirect to the config page.*/
    isLoggedIn && history.push(PATHS.CONFIG.AFTER_LOGIN)
    /* If the user is logged out, redirect to the logout page. */
    isLoggedOut && history.push(PATHS.CONFIG.AFTER_LOGOUT)
    /*   If the user's email is not verified, redirect to the email confirmation page. */
    isEmailNotVerified && history.push(PATHS.UNAUTHENTICATED.CONFIRM_EMAIL)
  }, [
    history,
    user,
    loading,
    isTemporaryPasswordResolved,
    _isSessionFetched,
    location.pathname
  ])

  // Session fetching error handling
  useEffect(() => {
    error && handleError(error)
  }, [error, handleError])

  return loading ? (
    <Box
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center">
      <Spinner text={t('Loading')} />
    </Box>
  ) : (
    children
  )
}

RoutesRedirect.propTypes = {
  children: PropTypes.element
}

export default RoutesRedirect
