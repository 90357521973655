const STATUSES = {
  NEWSPAPER_STATUSES: {
    ACTIVE: 'Active',
    BLOCKED: 'Blocked'
  },
  USER_STATUSES: {
    INVITED: 'Invited',
    ACTIVE: 'Active',
    BLOCKED: 'Blocked'
  },
  EDITOR_STATUSES: {
    INVITED: 'Invited',
    ACTIVE: 'Active',
    BLOCKED: 'Blocked'
  },
  REJECT_REASON_STATUSES: {
    ACTIVE: 'Active',
    BLOCKED: 'Blocked'
  },
  NEWS_TIP_STATUSES: {
    DRAFT: 'Draft',
    PENDING: 'Pending',
    APPROVED: 'Approved',
    REJECTED: 'Rejected'
  }
}
export { STATUSES }
export default STATUSES
