import { COLLECTIONS } from '__constants__'

import { Profile } from 'models'
import { message } from 'antd'
import { updateDocument } from 'services/api/firebase'
import { uploadImage } from 'helpers'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const useActionsProfileAdvancedForm = ({ initialData, form } = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  const { _id, updateSessionData } = useUser()
  /* Function for preparing values for saving */

  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Preparing user values */
    const preparedValues = {
      _id: _id,
      ...additionalValues,
      firstName: values?.['user']?.['firstName'] ?? null,
      lastName: values?.['user']?.['lastName'] ?? null,
      email: values?.['user']?.['email'] ?? null,
      phone: values?.['user']?.['phone'] ?? null,
      avatarUrl: (await uploadImage(values?.['user']?.['avatarUrl'])) ?? null
    }
    return preparedValues
  }
  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      // Save data
      await updateDocument(COLLECTIONS.EDITORS, initialData.user?._id, data)

      /* updating userContext */
      updateSessionData(data)
      message.success(t('Profile successfully updated'))

      // Final callback
      callback?.()
    } catch (error) {
      throw new Error(t('Something went wrong during data save'))
    }
  }

  /* Function for validating form */
  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        firstName: values?.['user']?.['firstName'],
        lastName: values?.['user']?.['lastName'],
        email: values?.['user']?.['email'],
        phone: values?.['user']?.['phone'],
        avatarUrl: values?.['user']?.['avatarUrl']
      }
      Profile.validationSchema.validateSync(validationData)
    } catch (error) {
      throw new Error(t('User validation error: ') + t(error.message))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)

      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsProfileAdvancedForm
