import { generatePath, useHistory } from 'react-router-dom'
import { getBlob, getMetadata, ref } from 'firebase/storage'

import { COLLECTIONS } from '__constants__'
import PATHS from 'pages/paths'
import { deleteDocument, updateDocument } from 'services/api/firebase'
import { message } from 'antd'
import storage from 'services/firebase/storage'
import { useEvent } from 'hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

/* Path's */
const createPath =
  PATHS.AUTHENTICATED.NEWS_TIP_CREATE || PATHS.AUTHENTICATED.NEWS_TIP_WIZARD
const editPath = PATHS.AUTHENTICATED.NEWS_TIP_EDIT
const showPath = PATHS.AUTHENTICATED.NEWS_TIP_SHOW

const useNewsTipBaseActions = ({ newsTipId, actions, extraProps } = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    history.push(createPath)
  })

  /* Handling opening of the show page */
  const handleOpen = useEvent(() => {
    const pathParams = { newsTipId }
    const path = generatePath(showPath, pathParams)
    history.push(path)
  })

  /* Handling opening of the edit page or change view to edit mode */
  const handleEdit = useEvent(() => {
    const pathParams = { newsTipId }
    const path = generatePath(editPath, pathParams)
    history.push(path)
  })

  const handleDelete = useEvent(async () => {
    try {
      await deleteDocument(COLLECTIONS.NEWS_TIPS, newsTipId)
      message.success(t('NewsTip successfully deleted'))
    } catch (error) {
      message.error(error.message)
    }
  })
  const handleCopy = useEvent(() => {
    const copyDetails = [
      {
        title: t('Content'),
        data: extraProps?.newsTip?.content
      },
      {
        title: t('Author'),
        data: extraProps?.author?.fullName
      },
      {
        title: t('Email'),
        data: extraProps?.author?.email
      },
      {
        title: t('Phone'),
        data: extraProps?.author?.phone
      },
      {
        title: t('Date and time'),
        data: extraProps?.newsTip?.dateAndTime
      },
      {
        title: t('Tags'),
        data: extraProps?.newsTip?.tags?.join(', ')
      }
    ]

    // Get formatted copy content
    const formattedCopyContent = copyDetails
      ?.filter(({ data }) => data)
      ?.map(({ title, data }) => `${title}: ${data}`)
      ?.join('\n')
    // Set copied content to clipboard
    // IDEA: change to formatted text, like html
    navigator.clipboard.writeText(formattedCopyContent)
  })

  const downloadFileToDevice = (blob, download, fileFormat, title, index) => {
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')

    const computedTitle = title?.replaceAll(' ', '_') || 'tippsNews'
    link.href = url

    link.setAttribute('download', `${computedTitle}-${++index}.${fileFormat}`)

    // Append to html link element page
    document.body.appendChild(link)

    // Start download
    link.click()

    // Clean up and remove the link
    link.parentNode.removeChild(link)
  }

  const handleDownload = async (urls, title) => {
    try {
      const temporaryDownloadLink = document.createElement('a')
      temporaryDownloadLink.style.display = 'none'

      // TODO in next PR, configure cors for native device
      const computedUrls = urls.map((item) => item?.replace(':443', ''))
      const data = computedUrls?.map(async (download, index) => {
        const reference = ref(storage, download)
        const blob = await getBlob(reference)
        const metadata = await getMetadata(reference)
        const fileFormat = metadata?.contentType?.split('/').at(-1)
        return downloadFileToDevice(blob, download, fileFormat, title, index)
      })

      await Promise.all(data)
    } catch (e) {
      message.error(t('Error during download files'))
    }
  }

  const handleUpdateStatus = useEvent(
    async ({ status, additionalFields = {} } = {}) => {
      try {
        await updateDocument(COLLECTIONS.NEWS_TIPS, newsTipId, {
          status: status,
          ...additionalFields
        })

        message.success(t('News tip successfully updated'))
      } catch (error) {
        message.error(error.message)
      }
    }
  )

  return useMemo(
    () => ({
      onBack: handleBack,
      onCreate: handleCreate,
      onOpen: handleOpen,
      onEdit: handleEdit,
      onDelete: handleDelete,
      onCopy: handleCopy,
      onDownload: handleDownload,
      onUpdateStatus: handleUpdateStatus,
      ...(actions || {}),
      createPath,
      editPath,
      showPath
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      actions?.onBack,
      actions?.onCreate,
      actions?.onDelete,
      actions?.onEdit,
      actions?.onOpen,
      actions?.onCopy,
      actions?.onDownload,
      handleBack,
      handleCreate,
      handleDelete,
      handleEdit,
      handleOpen,
      handleCopy,
      handleDownload
    ]
  )
}

export default useNewsTipBaseActions
