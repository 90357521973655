import { Form } from 'antd'
import { useEffect } from 'react'

const useInitializeNewsTipAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()
  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { newsTip } = initialData

      /* Formatting date fields */
      const formValues = {
        newsTip: {
          title: newsTip?.['title'] ?? null,
          points: newsTip?.['points'] ?? null,
          status: newsTip?.['status'] ?? null
        }
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])
  return { form }
}

export default useInitializeNewsTipAdvancedForm
