import { message } from 'antd'
import moment from 'moment'
import { useFilteredDataActions } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const useNewsTipFilterActions = ({
  form,
  filterData,
  setFilterData,
  setDisabledData
}) => {
  const { t } = useTranslations()

  const {
    findIndexInFilterDataWhere,
    removeValueFromFilteredData,
    addValueFromFilteredData
  } = useFilteredDataActions()

  // This function update filterData
  const updateData = (formValues) => {
    // return when formValues isn't array
    if (!Array.isArray(formValues))
      return message.error(t('Something went wrong during data filter'))

    const updatedFilterData = { ...filterData }

    formValues?.forEach?.((config) => {
      const [fieldName, operand, data] = config

      const isAllUsersType =
        fieldName === 'isAnonymous' && data !== null && data !== undefined

      // Check if there is a value in updatedFilteredData.where
      // If the value is not found, -1 will be returned
      const index = findIndexInFilterDataWhere(
        updatedFilterData,
        fieldName,
        operand
      )

      // If there is a value !!false and index not found, remove it from updatedFilterData.where
      if (!data && !isAllUsersType && index !== -1) {
        const removeValueFromFilteredDataProps = {
          updatedFilterData,
          fieldName,
          operand
        }

        const filteredData = removeValueFromFilteredData(
          removeValueFromFilteredDataProps
        )

        return (updatedFilterData.where = [...filteredData])
      }

      if (data || isAllUsersType) {
        if (index === -1) {
          //If the index is not found, add it to updatedData.where

          const addValueFromFilteredDataProps = {
            updatedFilterData,
            fieldName,
            operand,
            data
          }

          const newFilteredData = addValueFromFilteredData(
            addValueFromFilteredDataProps
          )

          return (updatedFilterData.where = newFilteredData)
        } else {
          //If the index is found, update data by index in updatedData.where

          return (updatedFilterData.where[index] = [
            ...[fieldName, operand, data]
          ])
        }
      }
    })

    setFilterData({ ...updatedFilterData })
  }

  // The function returns the unix value of the day of time for startAt - 00:00:00, endAt - 23:59:59
  const getFormattedUnixData = (data, isStart) => {
    const computedTime = isStart ? '00:00' : '23:59:59'
    const formattedData = moment(data).format(`MMM DD, YYYY ${computedTime}`)
    return Math.floor(new Date(formattedData).getTime() / 1000)
  }

  const onFinish = (data) => {
    const dateFieldName = '_createdAtMilliSeconds'

    const startAt = getFormattedUnixData(data?.startAt, true)
    const endAt = getFormattedUnixData(data?.endAt)

    const dataConfig = [
      [dateFieldName, '<=', endAt],
      [dateFieldName, '>=', startAt],
      ['isAnonymous', '==', data?.isAnonymous]
    ]

    updateData?.(dataConfig)
  }

  const onReset = () => {
    //Remove from filteredData values from filter
    setFilterData?.((prev) => {
      if (prev?.where?.length) {
        const updatedFilterData = { ...prev }

        const filteredData =
          updatedFilterData?.where
            ?.filter(
              (query) =>
                !query?.includes('_createdAtMilliSeconds') &&
                !query.includes('isAnonymous')
            )
            ?.filter(Boolean) || []

        updatedFilterData.where = [...filteredData]

        return updatedFilterData
      }

      return prev
    })

    // Reset disabled data in datepicker
    setDisabledData?.({ startAt: null, endAt: null })

    // Reset form fields
    form?.setFieldsValue?.({
      startAt: null,
      endAt: null,
      isAnonymous: null
    })
  }

  return { onReset, onFinish }
}

export default useNewsTipFilterActions
