import { Col, Row, Text } from '@qonsoll/react-design'
import { Image, VideoPlayerWrapper } from './NewsTipMediaList.styles'

import { Card } from 'antd'
import PropTypes from 'prop-types'
import { VideoPlayer } from 'components'
import { useTranslations } from 'contexts/Translation'

const NewsTipMediaList = (props) => {
  const { photoUrls, videoUrl } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  const arePhotoUrlsExist = !!photoUrls?.length
  // should be simplified, but this is temporary check (this necessary due to the broken data from mobile app)
  const isVideoUrlExists = Array.isArray(videoUrl)
    ? !!videoUrl.length
    : !!videoUrl
  const isAnyMediaAttached = isVideoUrlExists || arePhotoUrlsExist

  return (
    <Card>
      <Row noOuterGutters>
        <Col cw={12} mb={2}>
          <Text variant="overline" type="secondary">
            {t('Media')}
          </Text>
        </Col>
      </Row>
      <Row noOuterGutters>
        {photoUrls?.map((url) => (
          <Col cw={[12, 6, 6, 4, 3]} key={url}>
            <Image src={url} />
          </Col>
        ))}
        {isVideoUrlExists && (
          <Col cw={[12, 6, 6, 4, 3]}>
            <VideoPlayerWrapper>
              <VideoPlayer src={videoUrl} />
            </VideoPlayerWrapper>
          </Col>
        )}

        {!isAnyMediaAttached && (
          <Text type="secondary">{t('There are no media attached')}</Text>
        )}
      </Row>
    </Card>
  )
}

NewsTipMediaList.propTypes = {
  photoUrls: PropTypes.array,
  videoUrl: PropTypes.string
}
export default NewsTipMediaList
