import { getDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'
import { message } from 'antd'
/**
 * Function is used to get translation for location by selected language
 * @param {string} locationId location's id
 * @param {string} language   language short code
 * @returns translation for location by language
 */
const getLocationByLanguage = async (locationId, language) => {
  try {
    /* Get location document */
    const locationData = await getDocument(COLLECTIONS.LOCATIONS, locationId)

    /* Get translations for location */
    const languages = locationData?.languages

    /* Get translation by language */
    return languages
      ? languages?.[language?.toUpperCase()] || languages['NO']
      : 'N/A'
  } catch (error) {
    message.error('Error during get location')
  }
}

export default getLocationByLanguage
