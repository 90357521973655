import { Col } from '@qonsoll/react-design'
import { Form } from 'antd'
import PropTypes from 'prop-types'
import { StyledDatePicker } from '../NewsTipFilter.styles'
import { useTranslations } from 'contexts/Translation'

const DateFilter = (props) => {
  const { disabledDate, setDisabledData } = props

  const { t } = useTranslations()

  const handleDisabledDate = (type) => (current) => {
    if (current) {
      if (type === 'startAt') {
        return current.valueOf() >= disabledDate?.endAt?.valueOf()
      }

      return current.valueOf() <= disabledDate?.startAt?.valueOf()
    }
  }

  const handleChange = (type) => (current) => {
    setDisabledData?.({ ...disabledDate, [type]: current })
    return current
  }

  return (
    <>
      <Col>
        <Form.Item name="startAt">
          <StyledDatePicker
            placeholder={t('Start date')}
            disabledDate={handleDisabledDate('startAt')}
            onChange={handleChange('startAt')}
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item name="endAt">
          <StyledDatePicker
            placeholder={t('End date')}
            disabledDate={handleDisabledDate('endAt')}
            onChange={handleChange('endAt')}
          />
        </Form.Item>
      </Col>
    </>
  )
}

DateFilter.propTypes = {
  disabledDate: PropTypes.object,
  setDisabledData: PropTypes.func,
  form: PropTypes.object
}

export default DateFilter
