const USERS = 'users'
const LOGS = 'logs'
const TIPS = 'tips'
const EDITORS = 'editors'
const NEWS = 'news'
const NEWS_TIPS = 'newsTips'
const NEWSPAPERS = 'newspapers'
const STORES = 'stores'
const REWARDS = 'rewards'
const NOTIFICATIONS = 'notifications'
const REJECT_REASONS = 'rejectReasons'
const SUBADMINS = 'subadmins'
const SUPER_ADMINS = 'superAdmins'
const NEWS_CATEGORIES = 'newsCategories'
const TIP_STATUSES = 'tipStatuses'
const USER_STATUSES = 'userStatuses'
const EDITOR_STATUSES = 'editorStatuses'
const NEWS_TIP_STATUSES = 'newsTipStatuses'
const STORE_STATUSES = 'storeStatuses'
const REWARD_STATUSES = 'rewardStatuses'
const REJECT_REASON_STATUSES = 'rejectReasonStatuses'
const SUBADMIN_STATUSES = 'subadminStatuses'
const NEWSPAPER_STATUSES = 'newspaperStatuses'
const COUPONS = 'coupons'
const REWARD_TYPES = 'rewardTypes'
const PAGES = 'pages'
const BADGES = 'badges'
const CONTENT = 'contents'
const EMAILS = 'emails'
const EMAIL_STATUSES = 'emailStatuses'
const TIPS_COMMENTS = 'tipsComments'
const LOCATIONS = 'locations'
const WEB_TIPPS_CONFIGURATIONS = 'webTippsConfigurations'

export const COLLECTIONS = {
  USERS,
  LOGS,
  TIPS,
  EDITORS,
  NEWS,
  NEWS_TIPS,
  NEWSPAPERS,
  STORES,
  REWARDS,
  NOTIFICATIONS,
  REJECT_REASONS,
  SUBADMINS,
  SUPER_ADMINS,
  NEWS_CATEGORIES,
  TIP_STATUSES,
  USER_STATUSES,
  EDITOR_STATUSES,
  NEWS_TIP_STATUSES,
  STORE_STATUSES,
  REWARD_STATUSES,
  REJECT_REASON_STATUSES,
  SUBADMIN_STATUSES,
  NEWSPAPER_STATUSES,
  COUPONS,
  REWARD_TYPES,
  PAGES,
  BADGES,
  CONTENT,
  EMAILS,
  EMAIL_STATUSES,
  TIPS_COMMENTS,
  LOCATIONS,
  WEB_TIPPS_CONFIGURATIONS
}
export default COLLECTIONS
