import { PageActions, PageWrapperActionsUnderTitle } from 'components'
import { UserNewsTipsFilter, UserNewsTipsTable } from 'domains/User/components'
import { useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { useFilter } from 'hooks'
import { useGetUserNewsTips } from 'domains/User/hooks'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const UserNewsTips = (props) => {
  const { user } = props

  const { t } = useTranslations()

  const { newspaperId } = useUser()

  const [sortedUserNewsTips, setSortedUserNewsTips] = useState()

  /* Getting filter params */
  const filterParams = useFilter('userNewsTips')
  /* Destructuring filter params */
  const { filterVisibility, filterData, setFilterVisibility } = filterParams

  const ref = useMemo(
    () => ({
      where: [
        ['_createdBy', '==', user?._id],
        ['newspaperId', '==', newspaperId],
        ...(filterData?.where || [])
      ]
    }),
    [filterData]
  )
  /* Getting relationship data */
  const [newsTips] = useGetUserNewsTips(ref)

  /* Configuring table header */
  const headingProps = {
    title: t('User News Tips List'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    search: true,
    filter: true
  }

  return (
    <PageWrapperActionsUnderTitle
      action={
        <PageActions
          {...filterParams}
          actions={actions}
          circle
          searchPlaceholder="Search by Title"
          searchField="title"
          collection={newsTips}
          setCollection={setSortedUserNewsTips}
          filterMenu={<UserNewsTipsFilter {...filterParams} />}
          filterVisibility={filterVisibility}
          setFilterVisibility={setFilterVisibility}
        />
      }
      headingProps={headingProps}>
      <UserNewsTipsTable dataSource={sortedUserNewsTips ?? newsTips} />
    </PageWrapperActionsUnderTitle>
  )
}

UserNewsTips.propTypes = {
  user: PropTypes.object.isRequired,
  actions: PropTypes.object
}

export default UserNewsTips
