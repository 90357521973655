import { convertUnixToTimestamp, getDataFromFilterData } from 'helpers'

import { useEffect } from 'react'

const useInitializeNewsTipFilter = ({ filterData, form, setDisabledData }) => {
  const initializeData = () => {
    const fieldName = '_createdAtMilliSeconds'

    const startAt = getDataFromFilterData(filterData, fieldName, '>=')
    const endAt = getDataFromFilterData(filterData, fieldName, '<=')
    const isAnonymous = getDataFromFilterData(filterData, 'isAnonymous', '==')

    const startAtTimestamp = convertUnixToTimestamp(startAt)
    const endAtTimestamp = convertUnixToTimestamp(endAt)

    const data = {
      startAt: startAtTimestamp ?? null,
      endAt: endAtTimestamp ?? null,
      isAnonymous: isAnonymous ?? null
    }

    form.setFieldsValue(data)
    setDisabledData(data)
  }

  useEffect(() => {
    filterData && initializeData()
  }, [])
}

export default useInitializeNewsTipFilter
