import { Card, Modal } from 'antd'

import styled from 'styled-components'

const ContentCard = styled(Card)`
  height: 100%;
`
const GeneralDetailsCard = styled(Card)`
  margin-bottom: 16px;
`

const StyledModal = styled(Modal)`
  .ant-modal-close {
    color: var(--ql-color-accent1);
  }

  .ant-modal-wrap {
    position: absolute !important;
  }

  max-width: 500px;
`

const cancelButtonStyle = { display: 'none' }

export { ContentCard, GeneralDetailsCard, StyledModal, cancelButtonStyle }
