import _ from 'lodash'
import { createFullUserName } from 'helpers'
import { useGetEditorNewspaper } from 'domains/Editor/hooks'

const useGetProfileAdvancedViewData = (user) => {
  /* Get user's newspaper */
  const [newspaper, loading] = useGetEditorNewspaper(user)

  /* Get user's full name */
  const fullName = createFullUserName(user?.firstName, user?.lastName)

  /* Get user's phone */
  const phone = user?.phone || 'N/A'

  /* Get user's phone */
  const email = user?.email || 'N/A'

  /* Get user's role */
  const role = _.upperFirst(_.lowerCase(user?.role))

  /* Get user's total tips */
  const totalTips = user?.totalTips

  /* Get user's newspaper name */
  const newspaperName = !loading && newspaper?.name

  /* Get user's status */
  const status = user?.status

  return {
    ...user,
    fullName,
    phone,
    email,
    role,
    totalTips,
    newspaperName,
    status,
    loading
  }
}

export default useGetProfileAdvancedViewData
