const REACT_APP_GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY

const initScripts = () => {
  /* Init script for react-places-autocomplete */
  const script = document.createElement('script')
  const head = document.head
  script.src = `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places`
  script.async = true

  head.appendChild(script)
}
export default initScripts
