import 'moment/locale/nb'

import { DEFAULT_LANGUAGE } from 'domains/Translation/__constants__'
import { LS } from '__constants__'
import moment from 'moment'

const initLocale = () => {
  const LCLanguage = localStorage.getItem(LS.LANGUAGE)
  const language = LCLanguage === DEFAULT_LANGUAGE ? 'nb' : LCLanguage
  moment.locale(language)
}

export default initLocale
