import { Col, Container, Row, Text, Title } from '@qonsoll/react-design'

import { AvatarWithLetter } from 'components'
import { Card } from 'antd'
import PropTypes from 'prop-types'
import { UserNewsTips } from 'domains/User/components'
import { createFullUserName } from 'helpers'
import { styles } from './UserAdvancedView.styles'
import { useGetNewsPaperData } from 'domains/Newspaper/hooks'
import { usePhoneNumber } from 'hooks'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const UserAdvancedView = (props) => {
  const { user } = props

  /* Getting editor newspaperId */
  const { newspaperId = null } = useUser()

  /* Getting translations instance */
  const { t } = useTranslations()
  const { transformPhoneNumber } = usePhoneNumber()
  const [newspaper] = useGetNewsPaperData(newspaperId)

  const userAssociatedNewsPaper = newspaper?.name || 'N/A'

  const computedPhoneNumber = user?.phoneNumber || user?.phone

  const userFullName = createFullUserName(user?.firstName, user?.lastName)
  const userNumber = transformPhoneNumber(computedPhoneNumber)
  const userPointsGiven = user?.pointsGiven?.[newspaperId] || 0
  const userPointsUsed = user?.pointsUsed?.[newspaperId] || 0

  return (
    <Container>
      <Row mx="var(--ql-grid-gutter-negative)">
        <Col cw={12} mb={3}>
          <Card>
            <Row
              noGutters
              mb={2}
              v="center"
              h="between"
              style={styles.rowStyles}>
              <Col h="left" cw="auto" minWidth="200px">
                <AvatarWithLetter user={user} />
              </Col>

              <Col>
                <Text type="secondary">{t('Name')}</Text>
                <Title level={5} mb={2}>
                  {userFullName}
                </Title>

                <Text type="secondary">{t('Mobile number')}:</Text>
                <Title level={5} mb={2}>
                  {userNumber}
                </Title>

                <Text type="secondary">{t('Email')}:</Text>
                <Title level={5} mb={2}>
                  {user?.email}
                </Title>

                <Text type="secondary">{t('NewsPaper associated')}:</Text>
                <Title level={5}>{userAssociatedNewsPaper}</Title>
              </Col>

              <Col h="left">
                <Text type="secondary">{t('Points used')}:</Text>
                <Title level={5} mb={2}>
                  {userPointsUsed}
                </Title>

                <Text type="secondary">{t('Points left')}:</Text>
                <Title level={5} mb={2}>
                  {userPointsGiven}
                </Title>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col cw={12}>
          <Card>
            <UserNewsTips user={user} />
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

UserAdvancedView.propTypes = {
  user: PropTypes.object
}

export default UserAdvancedView
