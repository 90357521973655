import { Button, Title } from '@qonsoll/react-design'
import { Form, Input, InputNumber } from 'antd'
import { KEYS, MAX_LENGTH_FIELD } from '__constants__'

import { NumericInput } from 'components'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const NewsTipSimpleForm = (props) => {
  const {
    initialValues,
    onSubmit,
    onCancel,
    showTitle,
    form,
    group,
    isTipsFromGuest
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()

  /* Getting used form from props or using base form */
  const usedForm = form || baseForm

  /* Resetting form on cancel */
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  /* Submitting form on enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('News tip')}
        </Title>
      )}
      <Form.Item
        label={t('Title')}
        name={[...group, 'title']}
        rules={[
          { max: MAX_LENGTH_FIELD, message: t('news tip title is too long!') }
        ]}>
        <Input disabled placeholder={t('Please enter your news tip title')} />
      </Form.Item>
      <Form.Item
        label={t('Points')}
        name={[...group, 'points']}
        rules={[
          {
            required: true,
            message: t('Please enter your news tip points!')
          }
        ]}>
        <NumericInput
          placeholder={t('Please enter your news tip points')}
          disabled={isTipsFromGuest}
        />
      </Form.Item>
    </>
  ) : (
    <Form
      form={usedForm}
      initialValues={initialValues}
      onFinish={onSubmit}
      layout="vertical"
      onKeyPress={handleKeyPress}
      autoComplete="off">
      {showTitle && (
        <Title level={4} mb={2}>
          {t('News tip')}
        </Title>
      )}
      <Form.Item
        label={t('Title')}
        name="title"
        rules={[
          { max: MAX_LENGTH_FIELD, message: t('News tip title is too long!') }
        ]}>
        <Input placeholder={t('Please enter your news tip title')} />
      </Form.Item>
      <Form.Item
        label={t('Points')}
        name="points"
        rules={[
          {
            required: false,
            message: t('Please enter your news tip points!')
          },
          { max: MAX_LENGTH_FIELD, message: t('News tip points is too long!') }
        ]}>
        <InputNumber
          min={0}
          placeholder={t('Please enter your news tip points')}
        />
      </Form.Item>
      {!form && (
        <>
          <Button mr={2} htmlType="button" onClick={handleCancel}>
            {t('Cancel')}
          </Button>
          <Button type="primary" onClick={() => usedForm.submit()}>
            {t('Save')}
          </Button>
        </>
      )}
    </Form>
  )
}

NewsTipSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array,
  isTipsFromGuest: PropTypes.bool
}

export default NewsTipSimpleForm
