import { Box, Button, Text } from '@qonsoll/react-design'
import {
  DEFAULT_LANGUAGE,
  ENGLISH_SHORT_CODE_LANGUAGE
} from 'domains/Translation/__constants__'
import { Dropdown, Menu } from 'antd'
import React, { useMemo } from 'react'
import {
  iconLanguageSelectedStyles,
  iconLanguageStyles
} from './LanguageButtonStyled.style'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslations } from 'contexts/Translation'

const LanguageMenu = ({ color }) => {
  const { language, languages, setCurrentLanguage } = useTranslations()

  const languageCode = language?.toUpperCase()
  const shortCodetoUpperCase = (item) => item?.shortCode?.toUpperCase()

  const handleChange = (value) => {
    setCurrentLanguage(value)
    moment.locale(value === DEFAULT_LANGUAGE ? 'nb' : value)
  }

  const initLanguage = useMemo(() => {
    const currentLanguage = languages?.find(({ value }) => value === language)
    //In case when language was removed but in the same time was selected
    if (!currentLanguage) {
      const defaultLanguage = languages?.find(
        ({ value }) => value === ENGLISH_SHORT_CODE_LANGUAGE
      )

      handleChange(defaultLanguage?.shortCode)
      return defaultLanguage?.shortCode
    }

    return currentLanguage?.shortCode
  }, [language, languages])

  const languageMenu = (
    <Menu defaultSelectedKeys={initLanguage} selectable>
      {languages?.map((item) => (
        <Menu.Item
          key={item?.shortCode}
          onClick={() => {
            handleChange(item?.shortCode)
          }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            <Text>{shortCodetoUpperCase(item)}</Text>
            {language === item?.shortCode && (
              <Icon {...iconLanguageSelectedStyles} />
            )}
          </Box>
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={languageMenu} placement="bottomRight" trigger="click">
      <Button color={color} type="text" width="90px">
        <Icon {...iconLanguageStyles} />
        <Text color="inherit">{languageCode}</Text>
      </Button>
    </Dropdown>
  )
}
LanguageMenu.propTypes = {
  color: PropTypes.string
}

export default LanguageMenu
