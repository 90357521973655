import { useCallback, useEffect, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import { createFullUserName } from 'helpers'
import { getDocument } from 'services/api/firebase'
import { getLocationByLanguage } from 'helpers'
import { useTranslations } from 'contexts/Translation'

const useGetCustomDataSource = (dataSource, collectionData) => {
  /* Getting selected language short code instance */
  const { language } = useTranslations()

  const [customDataSource, setCustomDataSource] = useState()
  const [loading, setLoading] = useState(false)

  const getQueryData = (collectionName, documentId) => ({
    collectionName,
    documentId
  })

  const getQueryConfig = useCallback((data) => {
    return {
      newspaper:
        data?.newspaperId &&
        getQueryData(COLLECTIONS.NEWSPAPERS, data?.newspaperId),

      store: data?.storeId && getQueryData(COLLECTIONS.STORES, data?.storeId),

      rewardType:
        data?.rewardTypeId &&
        getQueryData(COLLECTIONS.REWARD_TYPES, data?.rewardTypeId),

      status:
        data?.statusId && getQueryData(collectionData?.status, data?.statusId),

      user:
        collectionData?.fetchUserData &&
        data?._createdBy &&
        getQueryData(COLLECTIONS.USERS, data?._createdBy),

      location: data?.locationId
    }
  }, [])

  const getCustomDataSource = useCallback(async () => {
    try {
      setLoading(true)
      const customDataSource = dataSource?.map(async (data, index) => {
        const additionalData = {}
        const queryConfig = getQueryConfig(data)

        try {
          const additionalDataPromise = Object.entries(queryConfig)?.map(
            async (queryValues) => {
              const [field, query] = queryValues

              // Checks if there is data in queryConfig
              if (field && query) {
                const isLocation = field === 'location'

                //if field is a location, we get this data
                if (isLocation)
                  return (additionalData[field] =
                    query && (await getLocationByLanguage(query, language)))

                //if field is a !location, we get document from collection
                additionalData[field] = await getDocument(
                  query?.collectionName,
                  query?.documentId
                )
              }
            }
          )
          await Promise.all(additionalDataPromise)
        } catch (e) {
          console.error(e)
        }

        if (additionalData?.user?.firstName || additionalData?.user?.lastName)
          additionalData.user.fullName = createFullUserName(
            additionalData?.user?.firstName,
            additionalData?.user?.lastName
          )

        return { ...data, ...additionalData }
      })

      const customDataSourcePromise = await Promise.all(customDataSource)
      setCustomDataSource(customDataSourcePromise)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [dataSource, getQueryConfig, language])

  useEffect(() => {
    dataSource && getCustomDataSource()
  }, [dataSource, getCustomDataSource, language])

  return [customDataSource, loading]
}
export default useGetCustomDataSource
