import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { HeaderBreadcrumbs, PageActions, Spinner } from 'components'
import { useHistory, useParams } from 'react-router-dom'

import { COLLECTIONS } from '__constants__'
import { UserAdvancedView } from 'domains/User/components'
import { useDocument } from 'services/api/firebase'
import { useTranslations } from 'contexts/Translation'

const UserShowPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting params from router */
  const params = useParams()
  /* Fetching document data from useDocument hook */
  const [user, loading] = useDocument({
    ref: COLLECTIONS.USERS + '/' + params.userId
  })

  /* Configuring page header */
  const headingProps = {
    title: user?.name || t('User show'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Function to handle back click */
  const handleBack = () => history.goBack()

  return (
    <PageWrapper
      action={<PageActions />}
      onBack={handleBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('User loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <UserAdvancedView user={user} />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default UserShowPage
