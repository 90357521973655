import { COLLECTIONS } from '__constants__'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'

const useGetNewsPaperData = (newspaperId) => {
  const [user, loading, error] = useDocumentData(
    newspaperId && doc(firestore, COLLECTIONS.NEWSPAPERS, newspaperId)
  )
  return [user, loading, error]
}

export default useGetNewsPaperData
