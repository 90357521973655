import { COLLECTIONS, STATUSES } from '__constants__'

import { useCollection } from 'services/api/firebase'
import { useMemo } from 'react'

/**
 * It returns an array of values that are used to render the newsTips
 * @param props - An object that contains the query parameters.
 * @returns An array of values.
 */
const useGetNewsTips = (props) => {
  /* Query for getting collection reference */

  const { APPROVED, PENDING, REJECTED } = STATUSES.NEWS_TIP_STATUSES
  const statuses = [APPROVED, PENDING, REJECTED]

  const query = useMemo(() => {
    const query = { ref: COLLECTIONS.NEWS_TIPS }

    const computedOrderBy = [
      ['_createdAtMilliSeconds', 'desc'],
      ['_createdAt', 'desc'],
      ...(props?.orderBy || [])
    ]
    const computedWhere = [['status', 'in', statuses], ...(props?.where || [])]

    if (computedOrderBy?.length) query.orderBy = computedOrderBy
    if (computedWhere?.length) query.where = computedWhere

    return query
  }, [props])

  /* Getting collection data */
  return useCollection(query)
}

export default useGetNewsTips
