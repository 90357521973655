import { Input } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'

const NumericInput = (props) => {
  const { value, onChange, placeholder } = props

  const handleChange = (e) => {
    const { value: inputValue } = e.target
    const reg = /^\d*(\.\d*)?$/
    if (reg.test(inputValue)) {
      Number(inputValue) === 0 ? onChange(null) : onChange(+inputValue)
    }
  }

  return (
    <Input
      {...props}
      value={value || null}
      onChange={handleChange}
      placeholder={placeholder}
      maxLength={16}
    />
  )
}

NumericInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  handleChangeInput: PropTypes.func
}
export default NumericInput
