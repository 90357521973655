const useFilteredDataActions = () => {
  // This function returns the index of the element in the updatedFilterData.where array that matches the fieldName and operand parameters
  const findIndexInFilterDataWhere = (updatedFilterData, fieldName, operand) =>
    updatedFilterData?.where
      ? updatedFilterData?.where?.findIndex(
          (query) => query?.includes(fieldName) && query?.includes(operand)
        )
      : -1

  // This function remove value from updatedFilterData.where
  const removeValueFromFilteredData = ({
    updatedFilterData,
    fieldName,
    operand
  }) => {
    //Check and remove value by two parameters --> fieldName and operand
    const filteredData = updatedFilterData?.where
      ?.filter(
        (query) => !(query?.includes(fieldName) && query?.includes(operand))
      )
      ?.filter(Boolean)

    return filteredData
  }

  // This function add new value from updatedFilterData.where
  const addValueFromFilteredData = ({
    updatedFilterData,
    fieldName,
    operand,
    data
  }) => {
    return !Array.isArray(updatedFilterData.where)
      ? [[fieldName, operand, data]]
      : [...updatedFilterData.where, [fieldName, operand, data]]
  }

  return {
    removeValueFromFilteredData,
    addValueFromFilteredData,
    findIndexInFilterDataWhere
  }
}

export default useFilteredDataActions
