import { useEffect, useState } from 'react'
import { useEvent } from 'hooks'
import { message } from 'antd'
import { useTranslations } from '@qonsoll/translation'
import { ref, onValue } from 'firebase/database'
import { database } from 'services/firebase'
import { RDB_COLLECTIONS } from '__constants__'
import { useUser } from 'domains/User/context'

const useGetDashboardStatistic = () => {
  const { t } = useTranslations()
  const { newspaperId } = useUser()
  const [statisticData, setStatisticData] = useState({
    tipsApproved: 0,
    tipsReceived: 0,
    tipsRejected: 0
  })

  const getStatisticData = useEvent(async () => {
    try {
      const usersRef = ref(
        database,
        [
          RDB_COLLECTIONS.STATISTICS,
          RDB_COLLECTIONS.STATISTICS_NEWS_TIPS_BY_NEWSPAPER,
          newspaperId
        ].join('/')
      )

      //Get total tips by status value
      onValue(usersRef, (snapshot) => {
        snapshot.exists() &&
          setStatisticData((prev) => ({ ...prev, ...snapshot.val() }))
      })
    } catch (error) {
      message.error(t('Error while getting statistic data'))
    }
  })

  useEffect(() => {
    let isMount = true

    if (isMount && newspaperId) getStatisticData()

    return () => {
      isMount = false
    }
  }, [getStatisticData, newspaperId])

  return statisticData
}

export default useGetDashboardStatistic
