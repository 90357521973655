import firebase from 'firebase/compat/app'
import { useEffect, useState } from 'react'
import { TOKEN_SESSION_PROVIDERS } from '__constants__'
import { message } from 'antd'
import { useTranslations } from '@qonsoll/translation'

// This constant contains period of time while auth token is valid in milliseconds
const TOKEN_VALIDITY_PERIOD = 36_00_000

const useManageAuthToken = (tokenExpirationTime, updateTokenExpirationTime) => {
  // State that contains auth-token. Token is taken from localStorage if there is session and it's renewed
  // Otherwise setting null into state
  const [token, setToken] = useState(
    localStorage.getItem(TOKEN_SESSION_PROVIDERS.AUTH_TOKEN) || null
  )

  const { t } = useTranslations()
  /**
   * It takes a new token and stores it in localStorage and updates state
   * @param newToken - The new token.
   */
  const updateToken = (newToken) => {
    localStorage.setItem(TOKEN_SESSION_PROVIDERS.AUTH_TOKEN, newToken)
    setToken(newToken)
  }

  useEffect(() => {
    let isMounted = true
    let tokenUpdateInterval = null
    let onAuthChangeListener = null
    let firstTokenRefreshTimer = null

    if (isMounted) {
      onAuthChangeListener = firebase
        .auth()
        .onAuthStateChanged(async (authUserData) => {
          if (authUserData) {
            const currentTime = new Date().getTime()

            /**
             * This function is called when current time is more than or equal to token expiration time.
             * It gets a new token from the Firebase Auth API, updates the token in the state, updates token expiration time
             * and sets interval to start next token update procedure
             */
            const updateAuthToken = async () => {
              try {
                const newToken =
                  isMounted && (await authUserData.getIdToken(true))
                if (newToken) {
                  // Updating token value in state and in localStorage
                  updateToken(newToken)

                  // Calculating new token expiration time
                  const newExpirationTime =
                    new Date().getTime() + TOKEN_VALIDITY_PERIOD
                  // Updating new token expiration time in state and in localStorage
                  updateTokenExpirationTime(newExpirationTime)

                  // If there is no tokenUpdateInterval - then we set interval to trigger token update
                  if (!tokenUpdateInterval) {
                    tokenUpdateInterval = setInterval(
                      updateAuthToken,
                      TOKEN_VALIDITY_PERIOD
                    )
                  }
                }
              } catch (error) {
                message.error(t('Error occurred during token refresh'))
              }
              // Clearing timeout that was created to deal with token update on first page load
              if (firstTokenRefreshTimer) {
                clearTimeout(firstTokenRefreshTimer)
                firstTokenRefreshTimer = null
              }
            }

            // Call token update function if token has expired
            if (tokenExpirationTime <= currentTime) {
              updateAuthToken()
            } else {
              // Calculating when first token will expire
              const tokenWillExpireInMilliseconds =
                tokenExpirationTime - currentTime

              // Setting timeout to trigger token update function after token has expired
              firstTokenRefreshTimer = setTimeout(
                updateAuthToken,
                tokenWillExpireInMilliseconds
              )
            }
          }
        })
    }

    // Clean-up of listeners
    return () => {
      onAuthChangeListener && onAuthChangeListener()
      firstTokenRefreshTimer && clearTimeout(firstTokenRefreshTimer)
      tokenUpdateInterval && clearInterval(tokenUpdateInterval)

      isMounted = false
    }
  }, [t, tokenExpirationTime, updateTokenExpirationTime])

  return [token, updateToken]
}

export default useManageAuthToken
