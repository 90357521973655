import { Badge, Box } from '@qonsoll/react-design'

import styled from 'styled-components'

const StyledFilterBox = styled(Box)`
  width: 100%;
  display: ${({ filterVisibility }) => (filterVisibility ? 'flex' : 'none')};
`

const BadgeStyled = styled(Badge)`
  .ant-badge-count {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--ql-color-accent2) !important;
    border: 1px solid var(--ql-color-grey);
  }
`

export { StyledFilterBox, BadgeStyled }
