import { COLLECTIONS, ENDPOINTS } from '__constants__'
import { createDocument, getId } from 'services/api/firebase'
import { useHistory, useParams } from 'react-router-dom'

import { NewsTip } from 'models'
import { message } from 'antd'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const FIREBASE_FUNCTIONS_API_URL =
  process.env.REACT_APP_FIREBASE_FUNCTIONS_API_URL

const useActionsNewsTipAdvancedForm = ({ initialData, form } = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting params instance */
  const params = useParams()
  // get editor data
  const editor = useUser()
  /* Function for preparing values for saving */
  const prepareValues = (values = {}, additionalValues = {}) => {
    /* Getting id */
    const newsTipId =
      initialData?.['newsTip']?._id || getId(COLLECTIONS.NEWS_TIPS)

    /* Preparing newsTip values */
    const preparedValues = {
      _id: newsTipId,
      ...additionalValues,
      title: values?.['newsTip']?.['title'] ?? null,
      points: Number(values?.['newsTip']?.['points']) ?? null,
      status: values?.['newsTip']?.['status'] ?? null
    }
    if (params.userId) preparedValues['userId'] = params.userId
    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = prepareValues(values)

      // Save data
      if (initialData) {
        if (editor?._id) {
          const response = await fetch(
            FIREBASE_FUNCTIONS_API_URL + ENDPOINTS.UPDATE_POINTS,
            {
              method: 'POST',
              cache: 'no-cache',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                ...data,
                updatedBy: editor?._id,
                newsTipId: initialData?.newsTip?._id,
                points: data?.points,
                newspaperId: initialData?.newspaper?._id,
                userId: initialData?.newsTip?._createdBy
              })
            }
          )
          const responseData = await response.json()

          if (responseData?.statusCode === 500) {
            throw new Error(responseData.error)
          }
          message.success(t('News tip successfully updated'))
        } else {
          message.error(
            `Seems something to be wrong with your account, please try to re-login and try again, ${JSON.stringify(
              editor || {}
            )}`
          )

          setLoading(false)

          return
        }
      } else {
        await createDocument(COLLECTIONS.NEWS_TIPS, data, data._id)
        message.success(t('News tip successfully created'))
      }
      callback?.()
    } catch (error) {
      throw new Error(t('Something went wrong during data save'), error)
    }
  }

  /* Function for validating form */
  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        sno: values?.['newsTip']?.['sno'],
        title: values?.['newsTip']?.['title'],
        points: values?.['newsTip']?.['points']
      }
      NewsTip.validationSchema.validateSync(validationData)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('NewsTip validation error: ') + t(error.message))
    }
  }

  //

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsNewsTipAdvancedForm
