import { COLLECTIONS, STATUSES } from '__constants__'

import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'

const useGetRejectReasonsData = (rejectReasonId, modalAction) => {
  const [rejectReason, loading, error] = useDocumentData(
    rejectReasonId &&
      modalAction === STATUSES.NEWS_TIP_STATUSES.REJECTED &&
      doc(firestore, COLLECTIONS.REJECT_REASONS, rejectReasonId)
  )
  return [rejectReason, loading, error]
}

export default useGetRejectReasonsData
