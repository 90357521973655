const styles = {
  headerStyles: {
    alignItems: 'center',
    bg: 'var(--ql-color-accent1)',
    color: 'var(--ql-typography-text-color-primary-reverse)'
  },
  containerStyles: {
    flex: '1',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%'
  },
  iconStyles: {
    mr: 3,
    display: 'flex',
    alignItems: 'center',
    name: 'Bell3Outlined',
    fill: 'currentColor'
  }
}

export { styles }
