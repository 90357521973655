import { Button, Popconfirm, Space, Tooltip } from 'antd'
import { COLLECTIONS, STATUSES } from '__constants__'
import { Link, Text } from '@qonsoll/react-design'
import { generatePath, useHistory } from 'react-router-dom'

import { DeleteOutlined } from '@ant-design/icons'
import { EditOutlined } from '@ant-design/icons'
import { FullNameLink } from 'domains/User/components'
import MOMENT_FORMATS from '__constants__/momentFormats'
import { NewsTipLocation } from 'domains/NewsTip/components'
import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { actions } from 'react-table'
import moment from 'moment'
import { sortTableByAlphabet } from 'helpers'
import { updateDocument } from 'services/api/firebase'
import useGetColumsSearchProps from '../useGetColumnSearchProps'
import { useMemo } from 'react'
import { useNewsTipBaseActions } from 'domains/NewsTip/hooks'
import { useTranslations } from '@qonsoll/translation'

const TableActions = ({ newsTipId, tipsStatus, createdBy }) => {
  const actions = useNewsTipBaseActions({ newsTipId })
  const { t } = useTranslations()

  const isAllowedEdit = tipsStatus === STATUSES.NEWS_TIP_STATUSES.APPROVED

  const isAllowedDelete =
    tipsStatus === STATUSES.NEWS_TIP_STATUSES.PENDING ||
    tipsStatus === STATUSES.NEWS_TIP_STATUSES.DRAFT

  const computedEditTooltipTitle = createdBy
    ? t('Edit tip')
    : t('Impossible to edit tip that was created by guest')

  return (
    <Space>
      {actions?.onDelete && isAllowedDelete && (
        <Popconfirm
          okButtonProps={{ danger: true }}
          title={t('Are you sure want to remove tip?')}
          onConfirm={actions?.onDelete}
          okText={t('Yes')}
          cancelText={t('No')}>
          <Tooltip title={t('Delete tip')}>
            <Button
              type="text"
              onSubmit={actions.onDelete}
              icon={<DeleteOutlined />}
              itemName={t('tip')}
            />
          </Tooltip>
        </Popconfirm>
      )}
      {actions?.onEdit && isAllowedEdit && (
        <Tooltip title={computedEditTooltipTitle}>
          <Button
            disabled={!createdBy}
            type="text"
            onClick={actions.onEdit}
            icon={<EditOutlined />}
          />
        </Tooltip>
      )}
    </Space>
  )
}
const useNewspaperTableColumns = (pending, rejected) => {
  const { t } = useTranslations()

  const history = useHistory()

  const getColumnSearchProps = useGetColumsSearchProps()

  return useMemo(() => {
    const handleOpen = async (newsTipId) => {
      await updateDocument(COLLECTIONS.NEWS_TIPS, newsTipId, {
        isOpenedByEditor: true
      })
      history.push(
        generatePath(PATHS.AUTHENTICATED.NEWS_TIP_SHOW, {
          newsTipId
        })
      )
    }
    const tipsFontWeight = (record) =>
      record?.isOpenedByEditor
        ? 'inherit'
        : 'var(--ql-typography-heading-font-weight)'

    let columns = [
      {
        title: <Text fontWeight="600">{t('Sno')}</Text>,
        dataIndex: 'sno',
        width: 'var(--table-number-column-width)',
        key: 'sno',
        align: 'center',
        sorter: (a, b) => a?.sno - b?.sno,
        ...getColumnSearchProps('sno', 'sno'),
        render: (_, record) => (
          <Text
            className={
              record?.isOpenedByEditor ? 'tableCellCheckDetectingClass' : 'none'
            }
            fontWeight={tipsFontWeight(record)}>
            {record?.sno}
          </Text>
        )
      },
      {
        title: <Text fontWeight="600">{t('Title')}</Text>,
        dataIndex: 'title',
        key: 'title',
        align: 'center',
        onClick: actions?.onOpen,
        sorter: (a, b) => sortTableByAlphabet(a?.title, b?.title),
        ...getColumnSearchProps('title', 'Title'),
        render: (_, record) => (
          <Tooltip title={record?.title}>
            <Link
              ellipsis
              style={{
                maxWidth: 500
              }}
              fontWeight={tipsFontWeight(record)}
              onClick={() => handleOpen(record?._id)}>
              {record?.title}
            </Link>
          </Tooltip>
        )
      },
      {
        title: <Text fontWeight="600">{t('Name')}</Text>,
        dataIndex: 'user',
        key: 'user',
        align: 'center',
        sorter: (a, b) =>
          sortTableByAlphabet(a?.user?.fullName, b?.user?.fullName),
        ...getColumnSearchProps(['user', 'fullName'], `${t('Name')}`),
        render: (text, record) => (
          <FullNameLink
            style={{ fontWeight: tipsFontWeight(record), maxWidth: 300 }}
            text={record?.user?.fullName}
            userId={record?._createdBy}
            isEllipsis
          />
        )
      },
      {
        title: <Text fontWeight="600">{t('Date and time')}</Text>,
        width: 'var(--table-date-column-width)',
        dataIndex: '_createdAt',
        key: '_createdAt',
        align: 'center',
        sorter: (a, b) => a?._createdAt?.seconds - b?._createdAt?.seconds,
        render: (date, record) => (
          <Text fontWeight={tipsFontWeight(record)}>
            {moment
              .unix(date?.seconds)
              .format(MOMENT_FORMATS.MONTH_DAYS_COMMA_YEAR)}
          </Text>
        )
      },
      {
        title: <Text fontWeight="600">{t('Location')}</Text>,
        dataIndex: 'location',
        key: 'location',
        align: 'center',
        sorter: (a, b) => sortTableByAlphabet(a?.location, b?.location),
        ...getColumnSearchProps('location', 'Location'),
        render: (_, record) => (
          <Tooltip title={record?.location}>
            <NewsTipLocation
              newsTip={record}
              fontWeight={tipsFontWeight(record)}
              style={{
                maxWidth: 400
              }}
              ellipsis
            />
          </Tooltip>
        )
      },
      {
        title: <Text fontWeight="600">{t('Actions')}</Text>,
        dataIndex: 'actions',
        width: 'var(--table-actions-column-width)',
        key: 'actions',
        align: 'center',
        render: (_, record) => (
          <TableActions
            newsTipId={record?._id}
            isBlocked={record?.isBlocked}
            tipsStatus={record?.status}
            createdBy={record?._createdBy}
          />
        )
      }
    ]
    if (rejected) {
      columns = columns.filter(
        ({ dataIndex }) => !['actions'].includes(dataIndex)
      )
    }
    return columns
  }, [t, getColumnSearchProps, pending, history])
}
TableActions.propTypes = {
  newsTipId: PropTypes.string,
  tipsStatus: PropTypes.string,
  createdBy: PropTypes.string
}
export default useNewspaperTableColumns
