import PropTypes from 'prop-types'
import { Row, Col, Text } from '@qonsoll/react-design'
import { useGetNewsTipCommentSimpleViewData } from 'domains/NewsTipComment/hooks'
import { Avatar } from 'antd'

const NewsTipCommentSimpleView = ({ comment, author }) => {
  /* Getting all necessary data for comment view */
  const { fullName, avatarUrl, avatarText, createdAtFormatted, text } =
    useGetNewsTipCommentSimpleViewData(comment, author)

  return (
    <Row noGutters>
      <Col cw="auto" mr={3}>
        <Avatar src={avatarUrl} size={40}>
          {avatarText}
        </Avatar>
      </Col>
      <Col>
        <Row noGutters>
          <Col cw={12} mb={1}>
            <Text>{fullName}</Text>
          </Col>
          <Col cw={12}>
            <Text type="secondary">{text}</Text>
          </Col>
        </Row>
      </Col>
      <Col cw="auto">
        <Text type="secondary" variant="body2" fontStyle="italic">
          {createdAtFormatted}
        </Text>
      </Col>
    </Row>
  )
}

NewsTipCommentSimpleView.propTypes = {
  comment: PropTypes.shape({
    text: PropTypes.string,
    _createdAt: PropTypes.number
  }),
  author: PropTypes.shape({
    fullName: PropTypes.string,
    avatarUrl: PropTypes.string,
    email: PropTypes.string
  })
}

export default NewsTipCommentSimpleView
