import {
  useGetNewsTipAuthor,
  useGetNewsTipNewspaper
} from 'domains/NewsTip/hooks'

import { COLLECTIONS } from '__constants__'
import { getFormattedDateAndTime } from 'helpers'
import { useDocument } from 'services/api/firebase'
import { useLoading } from 'hooks'
import { useMemo } from 'react'

const useGetNewsTipInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [newsTip, newsTipLoading] = useDocument({
    ref: id ? COLLECTIONS.NEWS_TIPS + '/' + id : null
  })

  /* Fetching newspaper data */
  const [newspaper, newspaperLoading] = useGetNewsTipNewspaper(newsTip)

  /* Fetching author from DB */
  const [author, authorLoading] = useGetNewsTipAuthor(newsTip)

  /* Get formatted date and time */
  const dateAndTime =
    !newsTipLoading && getFormattedDateAndTime(newsTip?._createdAt)

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      newsTip: { ...newsTip, dateAndTime },
      newspaper,
      author
    }),
    [newsTip, newspaper, author, dateAndTime]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () =>
      id ? [!newsTip, newsTipLoading, newspaperLoading, authorLoading] : [],
    [id, newsTip, newsTipLoading, newspaperLoading, authorLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetNewsTipInitialValues
