import { Box, Select } from '@qonsoll/react-design'

import { Form } from 'antd'
import styled from 'styled-components'

export const filterBackground = [
  'var(--card-background)',
  'var(--card-background)',
  'var(--card-background)',
  'var(--card-background)'
]

export const filterMaxHeight = ['calc(100vh - 225px)', 'calc(100vh - 205px)']

export const StyledFilter = styled(Box)`
  display: flex;
  gap: 16px;
  border-radius: var(--ql-border-radius-default);
  margin-top: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  padding: 0px 32px 16px 32px;
  overflow: auto;
  width: 100%;
`

export const StyledSelect = styled(Select)`
  width: 100%;
  max-width: 300px;
`

export const FormStyled = styled(Form)`
  display: flex;
  align-items: center;
  width: 100%;

  .ant-form-item {
    margin: 0 !important;
    padding: 0 !important;
  }
`

export const buttonProps = {
  onFinish: { block: true, key: 'apply', type: 'primary' },
  onReset: { block: true, key: 'reset', type: 'ghost' }
}
