import { COLLECTIONS } from '__constants__'
import { createFullUserName } from 'helpers'
import { useDocument } from 'services/api/firebase'
/**
 * It fetches a user (author) document from Firestore.
 * @param newsTip - The newsTip object that we're getting the newspaper for.
 * @returns An array: first element is object with fields(fullName, email, phone, isAnonymous),
 * second element is loading value
 */
const useGetNewsTipAuthor = (newsTip) => {
  /* Get id of author news tip */
  const _createdBy = newsTip?._createdBy

  /* Get author document */
  const [newTipAuthor, newTipAuthorLoading] = useDocument({
    ref: _createdBy ? COLLECTIONS.USERS + '/' + _createdBy : null
  })

  /* Get all necessary fields */
  const firstName = newTipAuthor?.firstName
  const lastName = newTipAuthor?.lastName
  const email = newTipAuthor?.email
  const phone = newTipAuthor?.phone || newTipAuthor?.phoneNumber
  const pointsGiven = newTipAuthor?.pointsGiven

  /* Get author's full name */
  const fullName = newTipAuthor && createFullUserName(firstName, lastName)

  return [
    {
      fullName,
      email,
      phone,
      pointsGiven
    },
    newTipAuthorLoading
  ]
}

export default useGetNewsTipAuthor
