import { Col, PageWrapper, Row } from '@qonsoll/react-design'

import { PageActions, Spinner, HeaderBreadcrumbs } from 'components'
import { ProfileAdvancedView } from 'domains/Profile/components'
import { useProfileBaseActions } from 'domains/Profile/hooks'
import { useTranslations } from 'contexts/Translation'
import { useGetProfileAdvancedViewData } from 'domains/Profile/components/ProfileAdvancedView/hooks'
import { useUser } from 'domains/User/context'

const ProfileShow = () => {
  /* Get user from context */
  const user = useUser()

  /* Getting translations instance */
  const { t } = useTranslations()
  const { onEdit, onBack } = useProfileBaseActions()

  /* Get user's data */
  const profile = useGetProfileAdvancedViewData(user)

  /* Configuring page header */
  const headingProps = {
    title: t('Profile details'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = { edit: true }

  return (
    <PageWrapper
      action={<PageActions actions={actions} onEdit={onEdit} />}
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        <Col cw={12}>
          {profile?.loading ? (
            <Spinner flex={1} />
          ) : (
            <ProfileAdvancedView profile={profile} />
          )}
        </Col>
      </Row>
    </PageWrapper>
  )
}

export default ProfileShow
