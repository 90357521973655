import { Col, Container, Row, Text } from '@qonsoll/react-design'

import { AvatarWithLetter } from 'components'
import { Card } from 'antd'
import { DetailsCard } from './ProfileAdvancedView.styled'
import PropTypes from 'prop-types'
import { usePhoneNumber } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const ProfileAdvancedView = ({ profile }) => {
  const {
    fullName,
    role,
    status,
    phone,
    email,
    totalTips,
    newspaperName,
    phoneNumber
  } = profile

  /* Getting translations instance */
  const { t } = useTranslations()
  const { transformPhoneNumber } = usePhoneNumber()

  const personalDetailsConfig = [
    {
      title: 'Full name',
      data: fullName
    },

    {
      title: 'Role',
      data: role
    },

    {
      title: 'Status',
      data: status
    },
    {
      title: 'Phone',
      data: transformPhoneNumber(phoneNumber || phone)
    },
    {
      title: 'Email',
      data: email
    }
  ].filter(({ data }) => data)

  const serviceDetailsConfig = [
    {
      title: 'Newspaper',
      data: newspaperName
    },
    {
      title: 'Total tips',
      data: totalTips
    }
  ].filter(({ data }) => data)
  return (
    <Container>
      <Row mx="var(--ql-grid-gutter-negative)">
        <Col cw="auto">
          <Card>
            {/** Size of the avatar was increased for a better view */}
            <AvatarWithLetter user={profile} size={320} />
          </Card>
        </Col>
        <Col>
          <DetailsCard>
            <Row mb={2} noGutters>
              <Col cw={6}>
                {personalDetailsConfig?.map(({ title, data }) => (
                  <Row noGutters mb={3}>
                    <Col mb={1} cw={12} h="left">
                      <Text variant="overline" type="secondary">
                        {t(title)}:
                      </Text>
                    </Col>
                    <Col cw={12}>
                      <Text>{data}</Text>
                    </Col>
                  </Row>
                ))}
              </Col>
              <Col cw={6}>
                {serviceDetailsConfig?.map(({ title, data }) => (
                  <Row noGutters mb={3}>
                    <Col mb={1} cw={12} h="left">
                      <Text variant="overline" type="secondary">
                        {t(title)}:
                      </Text>
                    </Col>
                    <Col cw={12}>
                      <Text>{data}</Text>
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </DetailsCard>
        </Col>
      </Row>
    </Container>
  )
}

ProfileAdvancedView.propTypes = {
  profile: PropTypes.shape({
    fullName: PropTypes.string,
    role: PropTypes.string,
    status: PropTypes.string,
    phone: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    location: PropTypes.string,
    totalTips: PropTypes.number,
    newspaperName: PropTypes.string
  })
}

export default ProfileAdvancedView
