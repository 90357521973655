import {
  Box,
  Card,
  Col,
  Container,
  PageWrapper,
  Row,
  Text,
  Title
} from '@qonsoll/react-design'
import { NewsTipFilter, NewsTipTable } from 'domains/NewsTip/components'
import { PageActions, PageWrapperActionsUnderTitle, Spinner } from 'components'
import { StyledTab, styles } from './Dashboard.styles'
import { useFilter, useGetCustomDataSource, useStateWithStorage } from 'hooks'
import { useMemo, useState } from 'react'

import { Icon } from '@qonsoll/icons'
import { STATUSES } from '__constants__'
import { Tabs } from 'antd'
import { useGetDashboardStatistic } from 'domains/Statistic/hooks'
import { useGetNewsTips } from 'domains/NewsTip/hooks'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const Dashboard = () => {
  const { t } = useTranslations()
  /* Getting filter params */
  const { newspaperId = null } = useUser()
  const filterParams = useFilter('newsTip')
  const statisticData = useGetDashboardStatistic()
  const { TabPane } = Tabs
  const [sortCollection, setSortCollection] = useState()

  /* State for active tab control */
  const [lastActiveTab, setLastActiveTab] = useStateWithStorage(
    STATUSES.NEWS_TIP_STATUSES.APPROVED,
    `tips-last-active-tab`
  )

  const statisticItems = useMemo(
    () => [
      {
        description: t('Tips Received'),
        count: statisticData?.tipsReceived,
        icon: 'CoinsHandOutlined',
        bg: 'var(--ql-color-blue)'
      },
      {
        description: t('Tips Accepted'),
        count: statisticData?.tipsApproved,
        icon: 'ClipboardCheckOutlined',
        bg: 'var(--ql-color-yellow)'
      },
      {
        description: t('Tips  Rejected'),
        count: statisticData?.tipsRejected,
        icon: 'ClipboardXOutlined',
        bg: 'var(--ql-color-danger)'
      }
    ],
    [statisticData, t]
  )
  /* Destructuring filter params */
  const { filterVisibility, filterData, setFilterVisibility } = filterParams

  const ref = useMemo(() => {
    return {
      where: [['newspaperId', '==', newspaperId], ...(filterData.where || [])]
    }
  }, [newspaperId, filterData])

  // get all tips from this newspaper
  const [newsTips] = useGetNewsTips(ref)
  // filter newsTips according to status
  const filteredTips = useMemo(
    () => newsTips?.filter(({ status }) => status === lastActiveTab),
    [lastActiveTab, newsTips]
  )

  const [customDataSourceTips, loading] = useGetCustomDataSource(filteredTips, {
    fetchUserData: true
  })

  /* Callback function to handle tab change */
  const onChangeTab = (key) => setLastActiveTab(key)

  /* Configuring page actions */
  const actions = {
    filter: true,
    search: true
  }
  const headingProps = {
    title: t('Dashboard'),
    titleSize: 3,
    textAlign: 'left'
  }
  /* Tab items */
  const tabsData = [
    {
      title: t('New'),
      key: STATUSES.NEWS_TIP_STATUSES.PENDING,
      component: <NewsTipTable dataSource={sortCollection} pending />
    },
    {
      title: t('Accepted'),
      key: STATUSES.NEWS_TIP_STATUSES.APPROVED,
      component: <NewsTipTable dataSource={sortCollection} accepted />
    },
    {
      title: t('Rejected'),
      key: STATUSES.NEWS_TIP_STATUSES.REJECTED,
      component: <NewsTipTable dataSource={sortCollection} rejected />
    }
    // TODO remove this code on December 21 of 2024
    // Commented on December 21 2023 due to the regulations of new newspaper
    // {
    //   title: t('Draft'),
    //   key: STATUSES.NEWS_TIP_STATUSES.DRAFT,
    //   component: <NewsTipTable dataSource={sortCollection} draft />
    // }
  ]

  return (
    <Container bounded>
      <PageWrapper headingProps={headingProps}>
        <Row mx="var(--ql-grid-gutter-negative)" mb={4}>
          {statisticItems.map(({ description, count, icon, bg }) => (
            <Col key={description}>
              <Card>
                <Row noGutters>
                  <Col cw="auto">
                    <Box bg={bg} {...styles.boxStyles}>
                      <Icon name={icon} fill="var(--ql-color-white)" size={30}>
                        {icon}
                      </Icon>
                    </Box>
                  </Col>

                  <Col alignItems="flex-end" justifyContent="center">
                    <Text variant="body2" type="secondary">
                      {description}
                    </Text>
                    <Title level={4}>{count}</Title>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>

        <Card mb={2}>
          <PageWrapperActionsUnderTitle
            action={
              <PageActions
                {...filterParams}
                actions={actions}
                circle
                filterMenu={
                  <NewsTipFilter
                    {...filterParams}
                    setSortCollection={setSortCollection}
                    sortCollection={sortCollection}
                  />
                }
                filterVisibility={filterVisibility}
                filterData={filterData}
                setFilterVisibility={setFilterVisibility}
                searchField={[
                  'title',
                  'location',
                  ['user', 'fullName'],
                  'tags'
                ]}
                searchPlaceholder="Search by Title, Location, Tags, Name"
                collection={customDataSourceTips}
                setCollection={setSortCollection}
              />
            }>
            <StyledTab
              defaultActiveKey={lastActiveTab}
              activeKey={lastActiveTab}
              onChange={onChangeTab}>
              {tabsData.map(({ title, key, component }) => (
                <TabPane tab={title} key={key}>
                  {loading ? (
                    <Box height="30vh">
                      <Spinner flex={1} text={t('Loading news tips')} />
                    </Box>
                  ) : (
                    component
                  )}
                </TabPane>
              ))}
            </StyledTab>
          </PageWrapperActionsUnderTitle>
        </Card>
      </PageWrapper>
    </Container>
  )
}

export default Dashboard
