import { getDataFromFilterData } from 'helpers'
import { useEffect } from 'react'

const useInitializeUserNewsTipsFilter = ({ filterData, form }) => {
  const initializeData = () => {
    const fieldName = 'status'

    const status = getDataFromFilterData(filterData, fieldName, '==')

    const data = {
      status: status
    }

    form.setFieldsValue(data)
  }

  useEffect(() => {
    filterData && initializeData()
  }, [])
}

export default useInitializeUserNewsTipsFilter
