const PATHS = {
  CONFIG: {
    DEFAULT: '/auth',
    AFTER_LOGIN: '/dashboard',
    AFTER_LOGOUT: '/auth',
    AFTER_SIGNUP: '/dashboard'
  },
  UNAUTHENTICATED: {
    LOGIN: '/auth',
    LOGIN_WITH_EMAIL: '/auth/login-with-email',
    SIGNUP: '/auth/signup',
    SIGNUP_WITH_EMAIL: '/auth/sign-up-with-email',
    FORGOT_PASSWORD: '/auth/forgot-password',
    CONFIRM_EMAIL: '/auth/confirm-email'
  },
  SERVICE: {
    ACCESS_DENIED: '/service/access-denied',
    GDPR: '/service/gdpr',
    NOT_FOUND: '/service/404',
    TERMS_AND_CONDITIONS: '/service/terms-and-conditions'
  },
  AUTHENTICATED: {
    RESET_PASSWORD: '/resetPassword',
    DASHBOARD: '/dashboard',
    CONTENT: '/content',
    USER_SHOW: '/users/:userId',
    TRANSLATIONS: '/translations',
    SETTINGS: '/settings',
    STATISTICS: '/statistics',
    USERS_ALL: '/users',
    USER_CREATE: '/user/create',
    USER_EDIT: '/users/:userId/edit',
    TIP_SHOW: '/tips/:tipId',
    TIP_EDIT: '/tips/:tipId/edit',
    TIP_STATUS_SHOW: '/tip-statuses/:tipStatusId',
    TIP_STATUS_EDIT: '/tip-statuses/:tipStatusId/edit',
    TIP_STATUSES_ALL: '/tip-statuses',
    TIP_STATUS_CREATE: '/tip-status/create',
    NEWSPAPER_SHOW: '/newspapers/:newspaperId',
    NEWSPAPER_EDIT: '/newspapers/:newspaperId/edit',
    NEWSPAPERS_ALL: '/newspapers',
    NEWSPAPER_CREATE: '/newspaper/create',
    NEWSPAPER_STATUS_SHOW: '/newspaper-statuses/:newspaperStatusId',
    NEWSPAPER_STATUS_EDIT: '/newspaper-statuses/:newspaperStatusId/edit',
    NEWSPAPER_STATUSES_ALL: '/newspaper-statuses',
    NEWSPAPER_STATUS_CREATE: '/newspaper-status/create',
    EDITOR_SHOW: '/editors/:editorId',
    EDITOR_EDIT: '/editors/:editorId/edit',
    EDITORS_ALL: '/editors',
    EDITOR_CREATE: '/editor/create',
    EDITOR_STATUS_SHOW: '/editor-statuses/:editorStatusId',
    EDITOR_STATUS_EDIT: '/editor-statuses/:editorStatusId/edit',
    EDITOR_STATUSES_ALL: '/editor-statuses',
    EDITOR_STATUS_CREATE: '/editor-status/create',
    PROFILE_SHOW: '/profile',
    PROFILE_EDIT: '/profile/edit',
    NEWS_TIP_ALL: '/news-tips',
    NEWS_TIP_SHOW: '/news-tips/:newsTipId',
    NEWS_TIP_EDIT: '/news-tips/:newsTipId/edit',
    NEWS_TIP_STATUS_SHOW: '/news-tip-statuses/:newsTipStatusId',
    NEWS_TIP_STATUS_EDIT: '/news-tip-statuses/:newsTipStatusId/edit',
    NEWS_TIP_STATUSES_ALL: '/news-tip-statuses',
    NEWS_TIP_STATUS_CREATE: '/news-tip-status/create',
    STORES_ALL: '/stores',
    STORE_SHOW: '/stores/:storeId',
    STORE_CREATE: '/store/create',
    STORE_EDIT: '/stores/:storeId/edit',
    STORE_STATUS_SHOW: '/store-statuses/:storeStatusId',
    STORE_STATUS_EDIT: '/store-statuses/:storeStatusId/edit',
    STORE_STATUSES_ALL: '/store-statuses',
    STORE_STATUS_CREATE: '/store-status/create',
    EMAIL_STATUS_CREATE: '/emailStatus/create',
    COUPON_SHOW: '/coupons/:couponId',
    COUPON_EDIT: '/coupons/:couponId/edit',
    REWARDS_ALL: '/rewards',
    REWARD_SHOW: '/rewards/:rewardId',
    REWARD_CREATE: '/reward/create',
    REWARD_EDIT: '/rewards/:rewardId/edit',
    REWARD_TYPE_SHOW: '/reward-types/:rewardTypeId',
    REWARD_TYPE_EDIT: '/reward-types/:rewardTypeId/edit',
    REWARD_TYPES_ALL: '/reward-types',
    REWARD_TYPE_CREATE: '/reward-type/create',
    REWARD_STATUS_SHOW: '/reward-statuses/:rewardStatusId',
    REWARD_STATUS_EDIT: '/reward-statuses/:rewardStatusId/edit',
    REWARD_STATUSES_ALL: '/reward-statuses',
    REWARD_STATUS_CREATE: '/reward-status/create',
    REWARD_OVERVIEW_ALL: '/reward-overview',
    NOTIFICATIONS_ALL: '/notifications',
    NOTIFICATION_SHOW: '/notifications/:notificationId',
    NOTIFICATION_CREATE: '/notification/create',
    NOTIFICATION_EDIT: '/notifications/:notificationId/edit',
    SUBADMINS_ALL: '/subadmins',
    SUBADMIN_SHOW: '/subadmins/:subadminId',
    SUBADMIN_CREATE: '/subadmin/create',
    SUBADMIN_EDIT: '/subadmins/:subadminId/edit',
    ADMINS_ALL: '/superadmins',
    ADMIN_SHOW: '/superadmins/:superadminId',
    ADMIN_CREATE: '/admin/create',
    ADMIN_EDIT: '/superadmins/:superadminId/edit',
    SUBADMIN_STATUS_SHOW: '/subadmin-statuses/:subadminStatusId',
    SUBADMIN_STATUS_EDIT: '/subadmin-statuses/:subadminStatusId/edit',
    SUBADMIN_STATUSES_ALL: '/subadmin-statuses',
    SUBADMIN_STATUS_CREATE: '/subadmin-status/create',

    EMAILS_ALL: '/emails',
    EMAIL_EDIT: '/emails/:emailId/edit',
    EMAIL_CREATE: '/email/create',

    PAGES_ALL: '/pages',
    PAGE_SHOW: '/pages/:pageId',
    PAGE_CREATE: '/page/create',
    PAGE_EDIT: '/pages/:pageId/edit',

    // Maybe will be delete
    // BADGES_ALL: '/badges',
    // BADGE_SHOW: '/badges/:badgeId',
    // BADGE_CREATE: '/badge/create',
    // BADGE_EDIT: '/badges/:badgeId/edit',
    REJECT_REASON_ALL: '/reject-reasons',
    REJECT_REASON_STATUSES_ALL: '/reject-reason-statuses',
    REJECT_REASON_STATUS_SHOW: '/reject-reason-statuses/:rejectReasonStatusId',
    REJECT_REASON_STATUS_EDIT:
      '/reject-reason-statuses/:rejectReasonStatusId/edit',
    REJECT_REASON_STATUS_CREATE: '/reject-reason-status/create',
    USER_STATUSES_ALL: '/user-statuses',
    USER_STATUS_SHOW: '/user-statuses/:userStatusId',
    USER_STATUS_EDIT: '/user-statuses/:userStatusId/edit',
    USER_STATUS_CREATE: '/user-status/create',
    NEWS_CATEGORIES_ALL: '/news-categories',
    NEWS_CATEGORY_SHOW: '/news-categories/:newsCategoryId',
    NEWS_CATEGORY_EDIT: '/news-categories/:newsCategoryId/edit',
    NEWS_CATEGORY_CREATE: '/news-category/create',
    SETTINGS_REWARD_TYPES_ALL: '/settings/reward-types',
    SETTINGS_NEWSPAPER_STATUSES_ALL: '/settings/newspaper-statuses',
    SETTINGS_SUBADMIN_STATUSES_ALL: '/settings/subadmin-statuses',
    SETTINGS_REJECT_REASON_STATUSES_ALL: '/settings/reject-reason-statuses',
    SETTINGS_REWARD_STATUSES_ALL: '/settings/reward-statuses',
    SETTINGS_STORE_STATUSES_ALL: '/settings/store-statuses',
    SETTINGS_NEWS_TIP_STATUSES_ALL: '/settings/news-tip-statuses',
    SETTINGS_EDITOR_STATUSES_ALL: '/settings/editor-statuses',
    SETTINGS_USER_STATUSES_ALL: '/settings/user-statuses',
    SETTINGS_TIP_STATUSES_ALL: '/settings/tip-statuses',
    SETTINGS_NEWS_CATEGORIES_ALL: '/settings/news-categories',
    SETTINGS_EMAIL_STATUSES_ALL: '/settings/emailStatuses'
  }
}

export default PATHS
