import { useGetUser, useUserBaseActions } from 'domains/User/hooks'

import { Link } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import { createFullUserName } from 'helpers'

const FullNameLink = ({ text, userId, style, isEllipsis }) => {
  const { onOpen } = useUserBaseActions({ userId })

  /* Get user's document */
  const userIdQuery = !text && userId

  const [user, loading] = useGetUser(userIdQuery)

  /* Get user's full name */
  const displayName =
    text || createFullUserName(user?.firstName, user?.lastName)

  return (
    !loading &&
    (isEllipsis ? (
      <Tooltip title={displayName}>
        <Link ellipsis={isEllipsis} style={style} onClick={onOpen}>
          {displayName}
        </Link>
      </Tooltip>
    ) : (
      <Link ellipsis={isEllipsis} style={style} onClick={onOpen}>
        {displayName}
      </Link>
    ))
  )
}
FullNameLink.propTypes = {
  text: PropTypes.string,
  userId: PropTypes.string,
  style: PropTypes.object,
  isEllipsis: PropTypes.bool
}

export default FullNameLink
