import { COLLECTIONS } from '__constants__'
import { createFullUserName } from 'helpers'
import { useDocument } from 'services/api/firebase'

const useGetTitleInsteadId = (documentId, collectionName) => {
  // Get document data by passed collection name and documentId
  const [documentData, documentDataLoading] = useDocument({
    ref: documentId ? collectionName + '/' + documentId : null
  })

  const titleByCollection = {
    [COLLECTIONS.USERS]: createFullUserName(
      documentData?.firstName,
      documentData?.lastName
    ),

    [COLLECTIONS.NEWS_TIPS]: documentData?.title
  }

  const replacedField = titleByCollection?.[collectionName]

  return [replacedField, documentDataLoading]
}

export default useGetTitleInsteadId
