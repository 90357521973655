import { Tabs } from 'antd'
import styled from 'styled-components'

const StyledTab = styled(Tabs)`
  .ant-tabs-tab {
    padding-left: 10px;
    min-width: 100px;
  }
`
const styles = {
  boxStyles: {
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60px',
    height: '60px'
  }
}

export { StyledTab, styles }
