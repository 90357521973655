// import { CouponEdit, CouponShow } from './Coupon'
// import { EditorCreate, EditorEdit, EditorShow, EditorsAll } from './Editor'
// import {
//   EditorStatusCreate,
//   EditorStatusEdit,
//   EditorStatusShow,
//   EditorStatusesAll
// } from './EditorStatus'
import { ProfileShow, ProfileEdit } from './Profile'
// import { EmailStatusCreate, EmailStatusesAll } from './EmailStatus'
// import {
//   NewsCategoriesAll,
//   NewsCategoryCreate,
//   NewsCategoryEdit,
//   NewsCategoryShow
// } from './NewsCategory'
import { NewsTipEdit, NewsTipShow } from './NewsTip'
// import {
//   NewsTipStatusCreate,
//   NewsTipStatusEdit,
//   NewsTipStatusShow,
//   NewsTipStatusesAll
// } from './NewsTipStatus'
// import {
//   NewspaperCreate,
//   NewspaperEdit,
//   NewspaperShow,
//   NewspapersAll
// } from './Newspaper'
// import {
//   NewspaperStatusCreate,
//   NewspaperStatusEdit,
//   NewspaperStatusShow,
//   NewspaperStatusesAll
// } from './NewspaperStatus'
// import {
//   NotificationCreate,
//   NotificationEdit,
//   NotificationShow,
//   NotificationsAll
// } from './Notification'
// import {
//   RejectReasonStatusCreate,
//   RejectReasonStatusEdit,
//   RejectReasonStatusShow,
//   RejectReasonStatusesAll
// } from './RejectReasonStatus'
// import { RewardCreate, RewardEdit, RewardShow, RewardsAll } from './Reward'
// import {
//   RewardStatusCreate,
//   RewardStatusEdit,
//   RewardStatusShow,
//   RewardStatusesAll
// } from './RewardStatus'
// import {
//   RewardTypeCreate,
//   RewardTypeEdit,
//   RewardTypeShow,
//   RewardTypesAll
// } from './RewardType'
// import { StoreCreate, StoreEdit, StoreShow, StoresAll } from './Store'
// import {
//   StoreStatusCreate,
//   StoreStatusEdit,
//   StoreStatusShow,
//   StoreStatusesAll
// } from './StoreStatus'
// import { TipEdit, TipShow } from './Tip'
// import {
//   TipStatusCreate,
//   TipStatusEdit,
//   TipStatusShow,
//   TipStatusesAll
// } from './TipStatus'
// import { UserCreate, UserEdit, UsersAll } from './User'
// import {
//   UserStatusCreate,
//   UserStatusEdit,
//   UserStatusShow,
//   UserStatusesAll
// } from './UserStatus'
import Dashboard from './Dashboard/Dashboard'
import PATHS from '../paths'
// import { RejectReasonAll } from './RejectReason'
import { ResetPassword } from './ResetPassword'
// import { RewardOverviewAll } from './RewardOverview'
// import { Settings } from './Settings'
// import { Statistics } from './Statistics'
// import Translations from './Translations'
import { UserShow } from './User'

const {
  RESET_PASSWORD,
  DASHBOARD,
  USER_SHOW,
  // TRANSLATIONS,
  // SETTINGS,
  // STATISTICS,
  // SETTINGS_REWARD_TYPES_ALL,
  // SETTINGS_NEWSPAPER_STATUSES_ALL,
  // SETTINGS_REJECT_REASON_STATUSES_ALL,
  // SETTINGS_REWARD_STATUSES_ALL,
  // SETTINGS_STORE_STATUSES_ALL,
  // SETTINGS_NEWS_TIP_STATUSES_ALL,
  // SETTINGS_EDITOR_STATUSES_ALL,
  // SETTINGS_USER_STATUSES_ALL,
  // SETTINGS_TIP_STATUSES_ALL,
  // SETTINGS_NEWS_CATEGORIES_ALL,
  // USERS_ALL,
  // USER_CREATE,
  // USER_EDIT,
  // TIP_SHOW,
  // TIP_EDIT,
  // TIP_STATUS_SHOW,
  // TIP_STATUS_EDIT,
  // TIP_STATUSES_ALL,
  // TIP_STATUS_CREATE,
  // NEWSPAPER_SHOW,
  // NEWSPAPER_EDIT,
  // NEWSPAPERS_ALL,
  // NEWSPAPER_CREATE,
  // NEWSPAPER_STATUS_SHOW,
  // NEWSPAPER_STATUS_EDIT,
  // NEWSPAPER_STATUSES_ALL,
  // NEWSPAPER_STATUS_CREATE,
  // EDITOR_SHOW,
  // EDITOR_EDIT,
  // EDITORS_ALL,
  // EDITOR_CREATE,
  // EDITOR_STATUS_SHOW,
  // EDITOR_STATUS_EDIT,
  // EDITOR_STATUSES_ALL,
  // EDITOR_STATUS_CREATE,
  PROFILE_SHOW,
  PROFILE_EDIT,
  // NEWS_TIP_ALL,
  NEWS_TIP_SHOW,
  NEWS_TIP_EDIT
  // NEWS_TIP_STATUS_SHOW,
  // NEWS_TIP_STATUS_EDIT,
  // NEWS_TIP_STATUSES_ALL,
  // NEWS_TIP_STATUS_CREATE,
  // STORES_ALL,
  // STORE_SHOW,
  // STORE_CREATE,
  // STORE_EDIT,
  // STORE_STATUS_SHOW,
  // STORE_STATUS_EDIT,
  // STORE_STATUSES_ALL,
  // STORE_STATUS_CREATE,
  // COUPON_SHOW,
  // COUPON_EDIT,
  // REWARDS_ALL,
  // REWARD_SHOW,
  // REWARD_CREATE,
  // REWARD_EDIT,
  // REWARD_TYPE_SHOW,
  // REWARD_TYPE_EDIT,
  // REWARD_TYPES_ALL,
  // REWARD_TYPE_CREATE,
  // REWARD_STATUS_SHOW,
  // REWARD_STATUS_EDIT,
  // REWARD_STATUSES_ALL,
  // REWARD_STATUS_CREATE,
  // NOTIFICATIONS_ALL,
  // NOTIFICATION_SHOW,
  // NOTIFICATION_CREATE,
  // NOTIFICATION_EDIT,
  // SETTINGS_EMAIL_STATUSES_ALL,
  // EMAIL_STATUS_CREATE,
  // REJECT_REASON_ALL,
  // REJECT_REASON_STATUSES_ALL,
  // REJECT_REASON_STATUS_SHOW,
  // REJECT_REASON_STATUS_EDIT,
  // REJECT_REASON_STATUS_CREATE,
  // USER_STATUSES_ALL,
  // USER_STATUS_SHOW,
  // USER_STATUS_EDIT,
  // USER_STATUS_CREATE,
  // NEWS_CATEGORIES_ALL,
  // NEWS_CATEGORY_SHOW,
  // NEWS_CATEGORY_EDIT,
  // NEWS_CATEGORY_CREATE,
  // REWARD_OVERVIEW_ALL
} = PATHS.AUTHENTICATED

const MAIN_ROUTES = [
  { key: 'DASHBOARD', path: DASHBOARD, component: Dashboard, exact: true },
  { key: 'USER_SHOW', path: USER_SHOW, component: UserShow, exact: true },
  {
    key: 'RESET_PASSWORD',
    path: RESET_PASSWORD,
    component: ResetPassword,
    exact: true
  },
  // {
  //   key: 'TRANSLATIONS',
  //   path: TRANSLATIONS,
  //   component: Translations,
  //   exact: true
  // },
  // {
  //   key: 'SETTINGS',
  //   path: SETTINGS,
  //   component: Settings,
  //   exact: false
  // },
  // {
  //   key: 'STATISTICS',
  //   path: STATISTICS,
  //   component: Statistics,
  //   exact: false
  // },
  // { key: 'USER_EDIT', path: USER_EDIT, component: UserEdit, exact: true },
  // { key: 'TIP_EDIT', path: TIP_EDIT, component: TipEdit, exact: true },
  // {
  //   key: 'TIP_STATUS_EDIT',
  //   path: TIP_STATUS_EDIT,
  //   component: TipStatusEdit,
  //   exact: true
  // },
  // {
  //   key: 'EMAIL_STATUS_CREATE',
  //   path: EMAIL_STATUS_CREATE,
  //   component: EmailStatusCreate,
  //   exact: true
  // },
  // {
  //   key: 'REWARD_OVERVIEW_ALL',
  //   path: REWARD_OVERVIEW_ALL,
  //   component: () => <RewardOverviewAll />,
  //   exact: true
  // },
  // {
  //   key: 'NEWSPAPER_EDIT',
  //   path: NEWSPAPER_EDIT,
  //   component: NewspaperEdit,
  //   exact: true
  // },
  // {
  //   key: 'NEWSPAPER_STATUS_EDIT',
  //   path: NEWSPAPER_STATUS_EDIT,
  //   component: NewspaperStatusEdit,
  //   exact: true
  // },
  // { key: 'EDITOR_SHOW', path: EDITOR_SHOW, component: EditorShow, exact: true },
  // { key: 'EDITOR_EDIT', path: EDITOR_EDIT, component: EditorEdit, exact: true },
  // { key: 'EDITORS_ALL', path: EDITORS_ALL, component: EditorsAll, exact: true },
  // {
  //   key: 'EDITOR_CREATE',
  //   path: EDITOR_CREATE,
  //   component: EditorCreate,
  //   exact: true
  // },
  {
    key: 'PROFILE_SHOW',
    path: PROFILE_SHOW,
    component: ProfileShow,
    exact: true
  },
  {
    key: 'PROFILE_EDIT',
    path: PROFILE_EDIT,
    component: ProfileEdit,
    exact: true
  },
  // {
  //   key: 'EDITOR_STATUS_SHOW',
  //   path: EDITOR_STATUS_SHOW,
  //   component: EditorStatusShow,
  //   exact: true
  // },
  // {
  //   key: 'EDITOR_STATUS_EDIT',
  //   path: EDITOR_STATUS_EDIT,
  //   component: EditorStatusEdit,
  //   exact: true
  // },
  // {
  //   key: 'EDITOR_STATUSES_ALL',
  //   path: EDITOR_STATUSES_ALL,
  //   component: EditorStatusesAll,
  //   exact: true
  // },
  // {
  //   key: 'EDITOR_STATUS_CREATE',
  //   path: EDITOR_STATUS_CREATE,
  //   component: EditorStatusCreate,
  //   exact: true
  // },
  {
    key: 'NEWS_TIP_EDIT',
    path: NEWS_TIP_EDIT,
    component: NewsTipEdit,
    exact: true
  },
  // {
  //   key: 'NEWS_TIP_STATUS_EDIT',
  //   path: NEWS_TIP_STATUS_EDIT,
  //   component: NewsTipStatusEdit,
  //   exact: true
  // },
  // { key: 'STORE_EDIT', path: STORE_EDIT, component: StoreEdit, exact: true },
  // {
  //   key: 'STORE_STATUS_EDIT',
  //   path: STORE_STATUS_EDIT,
  //   component: StoreStatusEdit,
  //   exact: true
  // },
  // { key: 'COUPON_EDIT', path: COUPON_EDIT, component: CouponEdit, exact: true },
  // { key: 'REWARD_EDIT', path: REWARD_EDIT, component: RewardEdit, exact: true },
  // {
  //   key: 'REWARD_TYPE_EDIT',
  //   path: REWARD_TYPE_EDIT,
  //   component: RewardTypeEdit,
  //   exact: true
  // },
  // {
  //   key: 'REWARD_STATUS_EDIT',
  //   path: REWARD_STATUS_EDIT,
  //   component: RewardStatusEdit,
  //   exact: true
  // },
  // {
  //   key: 'NOTIFICATION_EDIT',
  //   path: NOTIFICATION_EDIT,
  //   component: NotificationEdit,
  //   exact: true
  // },
  // { key: 'BADGE_EDIT', path: BADGE_EDIT, component: BadgeEdit, exact: true },
  // {
  //   key: 'REJECT_REASON_ALL',
  //   path: REJECT_REASON_ALL,
  //   component: RejectReasonAll,
  //   exact: true
  // },
  // {
  //   key: 'REJECT_REASON_STATUS_EDIT',
  //   path: REJECT_REASON_STATUS_EDIT,
  //   component: RejectReasonStatusEdit,
  //   exact: true
  // },
  // {
  //   key: 'USER_STATUS_EDIT',
  //   path: USER_STATUS_EDIT,
  //   component: UserStatusEdit,
  //   exact: true
  // },
  // {
  //   key: 'NEWS_CATEGORY_EDIT',
  //   path: NEWS_CATEGORY_EDIT,
  //   component: NewsCategoryEdit,
  //   exact: true
  // },
  // { key: 'USERS_ALL', path: USERS_ALL, component: UsersAll, exact: true },
  { key: 'USER_SHOW', path: USER_SHOW, component: UserShow, exact: true },
  // { key: 'USER_CREATE', path: USER_CREATE, component: UserCreate, exact: true },
  // { key: 'TIP_SHOW', path: TIP_SHOW, component: TipShow, exact: true },
  // {
  //   key: 'TIP_STATUS_SHOW',
  //   path: TIP_STATUS_SHOW,
  //   component: TipStatusShow,
  //   exact: true
  // },
  // {
  //   key: 'TIP_STATUSES_ALL',
  //   path: TIP_STATUSES_ALL,
  //   component: TipStatusesAll,
  //   exact: true
  // },
  // {
  //   key: 'TIP_STATUS_CREATE',
  //   path: TIP_STATUS_CREATE,
  //   component: TipStatusCreate,
  //   exact: true
  // },
  // {
  //   key: 'NEWSPAPER_SHOW',
  //   path: NEWSPAPER_SHOW,
  //   component: NewspaperShow,
  //   exact: true
  // },
  // {
  //   key: 'NEWSPAPERS_ALL',
  //   path: NEWSPAPERS_ALL,
  //   component: NewspapersAll,
  //   exact: true
  // },
  // {
  //   key: 'NEWSPAPER_CREATE',
  //   path: NEWSPAPER_CREATE,
  //   component: NewspaperCreate,
  //   exact: true
  // },
  // {
  //   key: 'NEWSPAPER_STATUS_SHOW',
  //   path: NEWSPAPER_STATUS_SHOW,
  //   component: NewspaperStatusShow,
  //   exact: true
  // },
  // {
  //   key: 'NEWSPAPER_STATUSES_ALL',
  //   path: NEWSPAPER_STATUSES_ALL,
  //   component: NewspaperStatusesAll,
  //   exact: true
  // },
  // {
  //   key: 'NEWSPAPER_STATUS_CREATE',
  //   path: NEWSPAPER_STATUS_CREATE,
  //   component: NewspaperStatusCreate,
  //   exact: true
  // },
  // {
  //   key: 'NEWS_TIP_ALL',
  //   path: NEWS_TIP_ALL,
  //   component: NewsTipAll,
  //   exact: true
  // },
  {
    key: 'NEWS_TIP_SHOW',
    path: NEWS_TIP_SHOW,
    component: NewsTipShow,
    exact: true
  }
  // {
  //   key: 'NEWS_TIP_STATUS_SHOW',
  //   path: NEWS_TIP_STATUS_SHOW,
  //   component: NewsTipStatusShow,
  //   exact: true
  // },
  // {
  //   key: 'NEWS_TIP_STATUSES_ALL',
  //   path: NEWS_TIP_STATUSES_ALL,
  //   component: NewsTipStatusesAll,
  //   exact: true
  // },
  // {
  //   key: 'NEWS_TIP_STATUS_CREATE',
  //   path: NEWS_TIP_STATUS_CREATE,
  //   component: NewsTipStatusCreate,
  //   exact: true
  // },
  // { key: 'STORES_ALL', path: STORES_ALL, component: StoresAll, exact: true },
  // { key: 'STORE_SHOW', path: STORE_SHOW, component: StoreShow, exact: true },
  // {
  //   key: 'STORE_CREATE',
  //   path: STORE_CREATE,
  //   component: StoreCreate,
  //   exact: true
  // },
  // {
  //   key: 'STORE_STATUS_SHOW',
  //   path: STORE_STATUS_SHOW,
  //   component: StoreStatusShow,
  //   exact: true
  // },
  // {
  //   key: 'STORE_STATUSES_ALL',
  //   path: STORE_STATUSES_ALL,
  //   component: StoreStatusesAll,
  //   exact: true
  // },
  // {
  //   key: 'STORE_STATUS_CREATE',
  //   path: STORE_STATUS_CREATE,
  //   component: StoreStatusCreate,
  //   exact: true
  // },
  // { key: 'COUPON_SHOW', path: COUPON_SHOW, component: CouponShow, exact: true },
  // { key: 'REWARDS_ALL', path: REWARDS_ALL, component: RewardsAll, exact: true },
  // { key: 'REWARD_SHOW', path: REWARD_SHOW, component: RewardShow, exact: true },
  // {
  //   key: 'REWARD_CREATE',
  //   path: REWARD_CREATE,
  //   component: RewardCreate,
  //   exact: true
  // },
  // {
  //   key: 'REWARD_TYPE_SHOW',
  //   path: REWARD_TYPE_SHOW,
  //   component: RewardTypeShow,
  //   exact: true
  // },
  // {
  //   key: 'REWARD_TYPES_ALL',
  //   path: REWARD_TYPES_ALL,
  //   component: RewardTypesAll,
  //   exact: true
  // },
  // {
  //   key: 'REWARD_TYPE_CREATE',
  //   path: REWARD_TYPE_CREATE,
  //   component: RewardTypeCreate,
  //   exact: true
  // },
  // {
  //   key: 'REWARD_STATUS_SHOW',
  //   path: REWARD_STATUS_SHOW,
  //   component: RewardStatusShow,
  //   exact: true
  // },
  // {
  //   key: 'REWARD_STATUSES_ALL',
  //   path: REWARD_STATUSES_ALL,
  //   component: RewardStatusesAll,
  //   exact: true
  // },
  // {
  //   key: 'REWARD_STATUS_CREATE',
  //   path: REWARD_STATUS_CREATE,
  //   component: RewardStatusCreate,
  //   exact: true
  // },
  // {
  //   key: 'NOTIFICATIONS_ALL',
  //   path: NOTIFICATIONS_ALL,
  //   component: NotificationsAll,
  //   exact: true
  // },
  // {
  //   key: 'NOTIFICATION_SHOW',
  //   path: NOTIFICATION_SHOW,
  //   component: NotificationShow,
  //   exact: true
  // },
  // {
  //   key: 'NOTIFICATION_CREATE',
  //   path: NOTIFICATION_CREATE,
  //   component: NotificationCreate,
  //   exact: true
  // },

  // Maybe will be delete
  // { key: 'BADGES_ALL', path: BADGES_ALL, component: BadgesAll, exact: true },
  // { key: 'BADGE_SHOW', path: BADGE_SHOW, component: BadgeShow, exact: true },
  // {
  //   key: 'BADGE_CREATE',
  //   path: BADGE_CREATE,
  //   component: BadgeCreate,
  //   exact: true
  // },
  // {
  //   key: 'REJECT_REASON_STATUSES_ALL',
  //   path: REJECT_REASON_STATUSES_ALL,
  //   component: RejectReasonStatusesAll,
  //   exact: true
  // },
  // {
  //   key: 'REJECT_REASON_STATUS_SHOW',
  //   path: REJECT_REASON_STATUS_SHOW,
  //   component: RejectReasonStatusShow,
  //   exact: true
  // },
  // {
  //   key: 'REJECT_REASON_STATUS_CREATE',
  //   path: REJECT_REASON_STATUS_CREATE,
  //   component: RejectReasonStatusCreate,
  //   exact: true
  // },
  // {
  //   key: 'USER_STATUSES_ALL',
  //   path: USER_STATUSES_ALL,
  //   component: UserStatusesAll,
  //   exact: true
  // },
  // {
  //   key: 'USER_STATUS_SHOW',
  //   path: USER_STATUS_SHOW,
  //   component: UserStatusShow,
  //   exact: true
  // },
  // {
  //   key: 'USER_STATUS_CREATE',
  //   path: USER_STATUS_CREATE,
  //   component: UserStatusCreate,
  //   exact: true
  // },
  // {
  //   key: 'NEWS_CATEGORIES_ALL',
  //   path: NEWS_CATEGORIES_ALL,
  //   component: NewsCategoriesAll,
  //   exact: true
  // },
  // {
  //   key: 'NEWS_CATEGORY_SHOW',
  //   path: NEWS_CATEGORY_SHOW,
  //   component: NewsCategoryShow,
  //   exact: true
  // },
  // {
  //   key: 'NEWS_CATEGORY_CREATE',
  //   path: NEWS_CATEGORY_CREATE,
  //   component: NewsCategoryCreate,
  //   exact: true
  // }
]

/* Config for settings pages */
// const settingsPagesProps = { withHeader: false, isListWithCreate: true }

const SETTINGS_ROUTES = [
  // { key: 'SETTINGS_REWARD_TYPES_ALL', path: SETTINGS_REWARD_TYPES_ALL, component: RewardTypesAll, exact: true },
  // {
  //   key: 'SETTINGS_REWARD_TYPES_ALL',
  //   path: SETTINGS_REWARD_TYPES_ALL,
  //   component: () => <RewardTypesAll {...settingsPagesProps} />,
  //   exact: true
  // },
  // { key: 'SETTINGS_NEWSPAPER_STATUSES_ALL', path: SETTINGS_NEWSPAPER_STATUSES_ALL, component: NewspaperStatusesAll, exact: true },
  // {
  //   key: 'SETTINGS_NEWSPAPER_STATUSES_ALL',
  //   path: SETTINGS_NEWSPAPER_STATUSES_ALL,
  //   component: () => <NewspaperStatusesAll {...settingsPagesProps} />,
  //   exact: true
  // },
  // { key: 'SETTINGS_REJECT_REASON_STATUSES_ALL', path: SETTINGS_REJECT_REASON_STATUSES_ALL, component: RejectReasonStatusesAll, exact: true },
  // {
  //   key: 'SETTINGS_REJECT_REASON_STATUSES_ALL',
  //   path: SETTINGS_REJECT_REASON_STATUSES_ALL,
  //   component: () => <RejectReasonStatusesAll {...settingsPagesProps} />,
  //   exact: true
  // },
  // { key: 'SETTINGS_REWARD_STATUSES_ALL', path: SETTINGS_REWARD_STATUSES_ALL, component: RewardStatusesAll, exact: true },
  // {
  //   key: 'SETTINGS_REWARD_STATUSES_ALL',
  //   path: SETTINGS_REWARD_STATUSES_ALL,
  //   component: () => <RewardStatusesAll {...settingsPagesProps} />,
  //   exact: true
  // },
  // { key: 'SETTINGS_STORE_STATUSES_ALL', path: SETTINGS_STORE_STATUSES_ALL, component: StoreStatusesAll, exact: true },
  // {
  //   key: 'SETTINGS_STORE_STATUSES_ALL',
  //   path: SETTINGS_STORE_STATUSES_ALL,
  //   component: () => <StoreStatusesAll {...settingsPagesProps} />,
  //   exact: true
  // },
  // { key: 'SETTINGS_NEWS_TIP_STATUSES_ALL', path: SETTINGS_NEWS_TIP_STATUSES_ALL, component: NewsTipStatusesAll, exact: true },
  // {
  //   key: 'SETTINGS_NEWS_TIP_STATUSES_ALL',
  //   path: SETTINGS_NEWS_TIP_STATUSES_ALL,
  //   component: () => <NewsTipStatusesAll {...settingsPagesProps} />,
  //   exact: true
  // },
  // { key: 'SETTINGS_EDITOR_STATUSES_ALL', path: SETTINGS_EDITOR_STATUSES_ALL, component: EditorStatusesAll, exact: true },
  // {
  //   key: 'SETTINGS_EDITOR_STATUSES_ALL',
  //   path: SETTINGS_EDITOR_STATUSES_ALL,
  //   component: () => <EditorStatusesAll {...settingsPagesProps} />,
  //   exact: true
  // },
  // { key: 'SETTINGS_USER_STATUSES_ALL', path: SETTINGS_USER_STATUSES_ALL, component: UserStatusesAll, exact: true },
  // {
  //   key: 'SETTINGS_USER_STATUSES_ALL',
  //   path: SETTINGS_USER_STATUSES_ALL,
  //   component: () => <UserStatusesAll {...settingsPagesProps} />,
  //   exact: true
  // },
  // { key: 'SETTINGS_TIP_STATUSES_ALL', path: SETTINGS_TIP_STATUSES_ALL, component: TipStatusesAll, exact: true },
  // {
  //   key: 'SETTINGS_TIP_STATUSES_ALL',
  //   path: SETTINGS_TIP_STATUSES_ALL,
  //   component: () => <TipStatusesAll {...settingsPagesProps} />,
  //   exact: true
  // },
  // {
  //   key: 'SETTINGS_EMAIL_STATUSES_ALL',
  //   path: SETTINGS_EMAIL_STATUSES_ALL,
  //   component: () => <EmailStatusesAll {...settingsPagesProps} />,
  //   exact: true
  // },
  // { key: 'SETTINGS_NEWS_CATEGORIES_ALL', path: SETTINGS_NEWS_CATEGORIES_ALL, component: NewsCategoriesAll, exact: true }
  // {
  //   key: 'SETTINGS_NEWS_CATEGORIES_ALL',
  //   path: SETTINGS_NEWS_CATEGORIES_ALL,
  //   component: () => <NewsCategoriesAll {...settingsPagesProps} />,
  //   exact: true
  // }
]

const STATISTICS_ROUTES = []

export { MAIN_ROUTES, SETTINGS_ROUTES, STATISTICS_ROUTES }
export default MAIN_ROUTES
