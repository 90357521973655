import { COLLECTIONS, ENDPOINTS, STATUSES } from '__constants__'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { message } from 'antd'
import { updateDocument } from 'services/api/firebase'
import { useTranslations } from 'contexts/Translation'

const FIREBASE_FUNCTIONS_API_URL =
  process.env.REACT_APP_FIREBASE_FUNCTIONS_API_URL

const useNewsTipModalAction = ({
  modalAction,
  actionDetails,
  rejectReasonText,
  rejectReasonData,
  author,
  editor,
  newsTip,
  setIsModalOpen,
  setModalAction,
  setActionDetails,
  setRejectReasonText
}) => {
  const { t } = useTranslations()

  const { newsTipId } = useParams()

  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const history = useHistory()

  const { NEWS_TIP_STATUSES } = STATUSES

  const handleOk = async () => {
    try {
      setLoadingSubmit(true)
      if (modalAction === NEWS_TIP_STATUSES.REJECTED) {
        await updateDocument(COLLECTIONS.NEWS_TIPS, newsTipId, {
          status: NEWS_TIP_STATUSES.REJECTED,
          rejectReason: rejectReasonText
        })
      }

      /* Updating the news tip status and points. */
      if (modalAction === NEWS_TIP_STATUSES.APPROVED) {
        const response = await fetch(
          FIREBASE_FUNCTIONS_API_URL + ENDPOINTS.ADD_POINTS,
          {
            method: 'POST',
            cache: 'no-cache',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              points: actionDetails,
              userId: newsTip?._createdBy,
              newspaperId: editor?.newspaperId,
              newsTipId: newsTip?._id,
              addedBy: editor?._id || editor?.uid
            })
          }
        )
        const responseData = await response.json()

        if (responseData?.statusCode === 500) {
          throw new Error(responseData.error)
        }
      }
      message.success(t('News tip was successfully updated'))
    } catch (error) {
      message.error(t(error?.message))
    } finally {
      setLoadingSubmit(false)
      setIsModalOpen(false)
      history.goBack()
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setModalAction(null)
    setActionDetails(null)
    setRejectReasonText(null)
  }

  const handleChange = (value) => setActionDetails(value)

  const handleChangeRejectReasonText = (e) => {
    setRejectReasonText(e?.target?.value)
  }

  // added check or author is anonymous
  const disabledSubmit = () => {
    if (modalAction === STATUSES.NEWS_TIP_STATUSES.APPROVED) {
      /* Disable submit button if there is empty input */
      return !(actionDetails || actionDetails === 0)
    } else {
      return !actionDetails
    }
  }

  useEffect(() => {
    modalAction === STATUSES.NEWS_TIP_STATUSES.REJECTED &&
      rejectReasonData?.text &&
      setRejectReasonText(rejectReasonData?.text)
  }, [rejectReasonData?.text, modalAction])

  return {
    handleOk,
    handleCancel,
    handleChangeRejectReasonText,
    handleChange,
    disabledSubmit,
    loadingSubmit
  }
}

export default useNewsTipModalAction
