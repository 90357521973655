import PATHS from 'pages/paths'
const TABLE_PATHS = {
  STORE_SHOW: PATHS.AUTHENTICATED.STORE_SHOW,
  REWARD_SHOW: PATHS.AUTHENTICATED.REWARD_SHOW,
  NEWSPAPER_SHOW: PATHS.AUTHENTICATED.NEWSPAPER_SHOW,
  EDITOR_SHOW: PATHS.AUTHENTICATED.EDITOR_SHOW
}

export { TABLE_PATHS }
export default TABLE_PATHS
