import { Box, Link, Text } from '@qonsoll/react-design'
import { Button, Input, Space } from 'antd'
import { generatePath, useHistory } from 'react-router-dom'
import { useCallback, useMemo, useRef } from 'react'

import MOMENT_FORMATS from '__constants__/momentFormats'
import { NewsTipLocation } from 'domains/NewsTip/components'
import { NewsTipNewsTipStatus } from 'domains/NewsTip/components'
import PATHS from 'pages/paths'
import { SearchOutlined } from '@ant-design/icons'
import { actions } from 'react-table'
import moment from 'moment'
import { sortTableByAlphabet } from 'helpers'
import { styles } from './useGetUserNewsTipTableColumns.styles'
import { useTranslations } from '@qonsoll/translation'

const useGetUserNewsTipTableColumns = (currentNewspaperTipsOnly) => {
  const { t } = useTranslations()
  const searchInput = useRef(null)
  const history = useHistory()

  const handleSearch = (selectedKeys, confirm, dataIndex) => confirm()
  const handleReset = (clearFilters) => clearFilters()

  const getColumnSearchProps = useCallback(
    (dataIndex, placeholder) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => (
        <Box p="8px">
          <Input
            ref={searchInput}
            placeholder={`${t('Search')} ${t(placeholder) || dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={styles.rowStyles}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small">
              {t('Search')}
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small">
              {t('Reset')}
            </Button>
          </Space>
        </Box>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? 'var(--ql-color-accent1)' : undefined
          }}
        />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100)
        }
      }
    }),
    [t]
  )

  return useMemo(() => {
    const handleOpen = (newsTipId) =>
      history.push(
        generatePath(PATHS.AUTHENTICATED.NEWS_TIP_SHOW, {
          newsTipId
        })
      )
    let finalArr = [
      {
        title: <Text fontWeight="600">{t('Sno')}</Text>,
        dataIndex: 'sno',
        width: 'var(--table-number-column-width)',
        key: 'sno',
        align: 'center',
        sorter: (a, b) => a?.sno - b?.sno,
        ...getColumnSearchProps('sno'),
        render: (_, record) => <Text>{record?.sno}</Text>
      },
      {
        title: <Text fontWeight="600">{t('Title')}</Text>,
        dataIndex: 'title',
        key: 'title',
        align: 'center',
        onClick: actions?.onOpen,
        sorter: (a, b) => sortTableByAlphabet(a?.title, b?.title),
        ...getColumnSearchProps('title', 'Title'),
        render: (_, record) => (
          <Link onClick={() => handleOpen(record?._id)}>{record?.title}</Link>
        )
      },
      {
        title: <Text fontWeight="600">{t('Posted on')}</Text>,
        width: 'var(--table-date-column-width)',
        dataIndex: '_createdAt',
        key: '_createdAt',
        align: 'center',
        sorter: (a, b) => a?._createdAt?.seconds - b?._createdAt?.seconds,
        render: (date) => (
          <Text>
            {moment
              .unix(date?.seconds)
              .format(MOMENT_FORMATS.MONTH_DAYS_COMMA_YEAR)}
          </Text>
        )
      },
      {
        title: <Text fontWeight="600">{t('Points')}</Text>,
        width: 'var(--table-points-column-width)',
        dataIndex: 'points',
        key: 'points',
        align: 'center',
        sorter: (a, b) => a?.points - b?.points,
        render: (_, record) => <Text>{record?.points}</Text>
      },
      {
        title: <Text fontWeight="600">{t('Location')}</Text>,
        width: 'var(--table-status-column-width)',
        dataIndex: 'location',
        key: 'location',
        align: 'center',
        sorter: (a, b) => sortTableByAlphabet(a?.location, b?.location),
        ...getColumnSearchProps('location', 'Location'),
        render: (_, record) => <NewsTipLocation newsTip={record} />
      },
      {
        title: <Text fontWeight="600">{t('Status')}</Text>,
        width: 'var(--table-status-column-width)',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        sorter: (a, b) => sortTableByAlphabet(a?.status, b?.status),
        render: (_, record) => <NewsTipNewsTipStatus newsTip={record} />
      }
    ]
    if (currentNewspaperTipsOnly) {
      finalArr = finalArr.filter(
        ({ dataIndex }) => !['postedBy', 'newspaper'].includes(dataIndex)
      )
    } else {
      finalArr = finalArr.filter(
        ({ dataIndex }) => !['phone'].includes(dataIndex)
      )
    }
    return finalArr
  }, [t, getColumnSearchProps, currentNewspaperTipsOnly, history])
}
export default useGetUserNewsTipTableColumns
