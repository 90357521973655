import { Form } from 'antd'
import { Option } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { StyledSelect } from '../NewsTipFilter.styles'
import { useTranslations } from 'contexts/Translation'

const TypeFilter = (props) => {
  const { placeholder } = props
  const { t } = useTranslations()

  const optionsConfig = [
    { value: null, label: t('All') },
    { value: true, label: t('Anonymous') },
    { value: false, label: t('Not anonymous') }
  ]
  const options = optionsConfig?.map(({ value, label }) => (
    <Option value={value} key={label}>
      {label}
    </Option>
  ))

  return (
    <Form.Item name="isAnonymous">
      <StyledSelect defaultValue={null} placeholder={placeholder}>
        {options}
      </StyledSelect>
    </Form.Item>
  )
}

TypeFilter.propTypes = {
  placeholder: PropTypes.string
}

export default TypeFilter
