import { Button, Title } from '@qonsoll/react-design'
import {
  useActionsProfileAdvancedForm,
  useInitializeProfileAdvancedForm
} from './hooks'

import { Form } from 'antd'
import { KEYS } from '__constants__'
import { ProfileSimpleForm } from 'domains/Profile/components'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const ProfileAdvancedForm = (props) => {
  const { initialData, group, showTitle } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form } = useInitializeProfileAdvancedForm(initialData)
  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsProfileAdvancedForm({
    initialData,
    form
  })

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('User')}
        </Title>
      )}
      <ProfileSimpleForm showTitle={false} group={[...group, 'user']} />
    </>
  ) : (
    <Form
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('User')}
        </Title>
      )}
      <ProfileSimpleForm group={['user']} />

      <Button mr={2} htmlType="button" onClick={onReset} mb={4}>
        {t('Cancel')}
      </Button>
      <Button type="primary" htmlType="submit" loading={loading}>
        {t('Save')}
      </Button>
    </Form>
  )
}

ProfileAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool,
  isSuperAdmin: PropTypes.bool
}

export default ProfileAdvancedForm
