import { GDPRPopup } from './domains/App/components'
import Navigator from './pages/Navigator'
import { initLocale } from 'utils'
/**
 * It returns a React component that renders a TranslationsProvider and a Navigator.
 * @returns The <TranslationsProvider> component.
 */
function App() {
  //It's placed here because when application is initialized,
  // need to check last selected language for avoiding mistakes of showing data,
  // which are related to moment library (for example date of creation)
  initLocale()

  return (
    <>
      <Navigator />
      <GDPRPopup />
    </>
  )
}

export default App
