import { message } from 'antd'
import { useFilteredDataActions } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const useUserNewsTipsFilterActions = ({ form, filterData, setFilterData }) => {
  const { t } = useTranslations()

  const {
    removeValueFromFilteredData,
    addValueFromFilteredData,
    findIndexInFilterDataWhere
  } = useFilteredDataActions()

  // This function update filterData
  const updateData = (formValues) => {
    // return when formValues isn't array
    if (!Array.isArray(formValues))
      return message.error(t('Something went wrong during data filter'))

    const updatedFilterData = { ...filterData }

    formValues?.forEach?.((config) => {
      const [fieldName, operand, data] = config

      // Check if there is a value in updatedFilteredData.where
      // If the value is not found, -1 will be returned
      const index = findIndexInFilterDataWhere(
        updatedFilterData,
        fieldName,
        operand
      )

      // If there is a value !!false and index not found, remove it from updatedFilterData.where
      if (!data && index !== -1) {
        const removeValueFromFilteredDataProps = {
          updatedFilterData,
          fieldName,
          operand
        }

        const filteredData = removeValueFromFilteredData(
          removeValueFromFilteredDataProps
        )

        return (updatedFilterData.where = [...filteredData])
      }

      if (data) {
        if (index === -1) {
          //If the index is not found, add it to updatedData.where

          const addValueFromFilteredDataProps = {
            updatedFilterData,
            fieldName,
            operand,
            data
          }

          const newFilteredData = addValueFromFilteredData(
            addValueFromFilteredDataProps
          )

          return (updatedFilterData.where = newFilteredData)
        } else {
          //If the index is found, update data by index in updatedData.where

          return (updatedFilterData.where[index] = [
            ...[fieldName, operand, data]
          ])
        }
      }
    })

    setFilterData({ ...updatedFilterData })
  }

  const onFinish = (data) => {
    const dateFieldName = 'status'

    const dataConfig = [[dateFieldName, '==', data?.status]]

    updateData?.(dataConfig)
  }

  const onReset = () => {
    //Remove from filteredData values from filter
    setFilterData?.((prev) => {
      if (prev?.where?.length) {
        const updatedFilterData = { ...prev }

        const filteredData =
          updatedFilterData?.where
            ?.filter((query) => !query?.includes('status'))
            ?.filter(Boolean) || []

        updatedFilterData.where = [...filteredData]

        return updatedFilterData
      }

      return prev
    })

    // Reset form fields
    form?.setFieldsValue?.({
      status: false
    })
  }

  return { onReset, onFinish }
}

export default useUserNewsTipsFilterActions
