import { Tabs } from 'antd'
import styled from 'styled-components'

const StyledTab = styled(Tabs)`
  .ant-tabs-tab {
    background-color: var(--btn-default-bg) !important;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0;
    color: var(--ql-typography-text-color-secondary) !important;
  }
  .ant-tabs-tab-active {
    background-color: var(--ql-color-accent1) !important;
    .ant-tabs-tab-btn {
      color: white;
    }
  }
  .ant-tabs-nav {
    margin-bottom: 0;
  }
  .ant-tabs-nav::before {
    display: none;
  }
`

export { StyledTab }
