import { useDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'
import { useTranslations } from '@qonsoll/translation'

const useGetLocation = (locationId) => {
  /* Getting translations instance */
  const { language } = useTranslations()

  /* Get location document */
  const [location, locationLoading] = useDocument({
    ref: locationId ? COLLECTIONS.LOCATIONS + '/' + locationId : null
  })

  /* Get object with formatted addresses */
  const languages = location?.languages

  /* Get translated address according to selected language */
  const formattedAddress = languages
    ? languages[language.toUpperCase()] || languages['NO']
    : 'N/A'

  return [
    {
      ...location,
      formattedAddress
    },
    locationLoading
  ]
}

export default useGetLocation
