import { Box, Option, Select, Text, TextArea } from '@qonsoll/react-design'
import { StyledModal, cancelButtonStyle } from './NewsTipActionModal.styles'
import {
  useGetNewsTipAuthor,
  useNewsTipModalAction
} from 'domains/NewsTip/hooks'
import {
  useGetRejectReasons,
  useGetRejectReasonsData
} from 'domains/RejectReason/hooks'

import { NumericInput } from 'components'
import PropTypes from 'prop-types'
import { STATUSES } from '__constants__'
import _ from 'lodash'
import { useGetNewsPaperData } from 'domains/Newspaper/hooks'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const NewsTipActionModal = ({
  isModalOpen,
  setIsModalOpen,
  modalAction,
  setModalAction,
  newsTip
}) => {
  const editor = useUser()

  /* Getting translations instance */
  const { t } = useTranslations()

  const [actionDetails, setActionDetails] = useState(null)

  const [author] = useGetNewsTipAuthor(newsTip)

  const [newspaper] = useGetNewsPaperData(editor?.newspaperId)

  const [rejectReasonData] = useGetRejectReasonsData(actionDetails, modalAction)

  const [rejectReasonText, setRejectReasonText] = useState(
    rejectReasonData?.text
  )

  const [rejectReasons] = useGetRejectReasons(editor?.newspaperId)

  const newsTipModalActionProps = {
    modalAction,
    actionDetails,
    rejectReasonText,
    rejectReasonData,
    author,
    editor,
    newsTip,
    setIsModalOpen,
    setModalAction,
    setActionDetails,
    setRejectReasonText
  }

  const {
    handleOk,
    handleCancel,
    handleChangeRejectReasonText,
    handleChange,
    disabledSubmit,
    loadingSubmit
  } = useNewsTipModalAction(newsTipModalActionProps)

  const modalTitle = _.upperFirst(_.lowerCase(modalAction))

  const pointsCalcInstruction = newspaper?.description

  return (
    <StyledModal
      okText={t('Save')}
      okButtonProps={{
        disabled: disabledSubmit(),
        type: 'primary',
        onClick: handleOk,
        loading: loadingSubmit
      }}
      cancelButtonProps={{ style: cancelButtonStyle }}
      title={t(modalTitle)}
      centered
      cancelText={t('Cancel')}
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}>
      {modalAction === STATUSES.NEWS_TIP_STATUSES.APPROVED ? (
        <>
          <Box flexDirection="column" mb={3}>
            <Text>{t('Points Criteria')}:</Text>
            <Text>{pointsCalcInstruction}</Text>
          </Box>
          <NumericInput
            mt={2}
            onChange={handleChange}
            value={actionDetails}
            placeholder={t('Set points for this news tip')}
          />
        </>
      ) : (
        <>
          <Select
            style={{ width: '100%' }}
            placeholder={t('Please, select news tip reject reason')}
            value={actionDetails}
            onSelect={handleChange}>
            {rejectReasons?.map((rejectReason) => (
              <Option key={rejectReason?._id} value={rejectReason?._id}>
                {rejectReason?.title}
              </Option>
            ))}
          </Select>

          <TextArea
            mt={3}
            placeholder={t('Select reject reason')}
            onChange={handleChangeRejectReasonText}
            value={rejectReasonText}
          />
        </>
      )}
    </StyledModal>
  )
}
NewsTipActionModal.propTypes = {
  newsTip: PropTypes.object,
  isModalOpen: PropTypes.bool,
  modalAction: PropTypes.string,
  setIsModalOpen: PropTypes.func,
  setModalAction: PropTypes.func
}
export default NewsTipActionModal
