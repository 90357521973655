import { Col, Container, Row, Text } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { Spinner } from 'components'
import _ from 'lodash'
import { useTranslations } from 'contexts/Translation'

export default function NewsTipNewsTipStatus(props) {
  const { newsTip, noDataTextProps } = props
  /* Getting translation instance */
  const { t } = useTranslations()
  const newsTipStatus = _.upperFirst(_.lowerCase(newsTip?.status))
  return (
    <Container>
      <Row noGutters>
        <Col>
          {newsTip?.status ? (
            <Text {...noDataTextProps}>{newsTipStatus}</Text>
          ) : (
            <Text type="secondary" variant="overline" {...noDataTextProps}>
              {t('No news tip statuses have been added yet')}
            </Text>
          )}
        </Col>
      </Row>
    </Container>
  )
}

NewsTipNewsTipStatus.propTypes = {
  newsTip: PropTypes.object.isRequired,
  noDataTextProps: PropTypes.object
}
