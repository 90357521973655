import { COLLECTIONS } from '__constants__'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { query, collection, where } from 'firebase/firestore'
import { firestore } from 'services/firebase'

const useGetNewspaperWebConfig = (newspaperId) => {
  const [data, loading] = useCollectionData(
    newspaperId &&
      query(
        collection(firestore, COLLECTIONS.WEB_TIPPS_CONFIGURATIONS),
        where('newspaperId', '==', newspaperId)
      )
  )

  return [data?.at(0) || null, loading]
}

export default useGetNewspaperWebConfig
