import { Col, Row, Text } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { useGetLocation } from 'domains/Location/hooks'

export default function NewsTipLocation(props) {
  const { newsTip, text, ...rest } = props

  /* Getting required data */
  const [newsTipsLocation, loading] = useGetLocation(
    !text && newsTip?.locationId
  )

  /* Getting translate for location according to selected language */
  const computedLocation = text || newsTipsLocation?.formattedAddress

  return (
    <Row noGutters>
      <Col>
        {loading ? (
          <Spinner flex={1} />
        ) : (
          (computedLocation || text) && (
            <Text {...rest}>{computedLocation}</Text>
          )
        )}
      </Col>
    </Row>
  )
}

NewsTipLocation.propTypes = {
  newsTip: PropTypes.object.isRequired,
  text: PropTypes.string
}
