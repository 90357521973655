import { AccountMenu, LayoutHeader, Logo } from './components'
import {
  Box,
  Col,
  Container,
  Header,
  Layout,
  LayoutSystemProvider,
  Row,
  Title
} from '@qonsoll/react-design'
import { Footer } from './components'
import Back from '../Back'
import { LanguageMenu } from 'domains/Translation/components'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import breakpoints from '../../styles/breakpoints'
import firebase from 'firebase/compat/app'
import { styles } from './BoilerplateLayout.styles'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useMemo } from 'react'
import { useStateWithStorage } from 'hooks'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'
import { Grid } from 'antd'

const { useBreakpoint } = Grid

const ASIDE_VISIBILITY = 'aside_visibility'

const BoilerplateLayout = ({ children }) => {
  const [authUser, loading] = useAuthState(firebase.auth())
  const user = useUser()

  const { firstName, lastName } = user

  const { t } = useTranslations()

  const displayName = firstName && lastName ? `${firstName} ${lastName}` : null

  /* If the authUser is authenticated, the component will render the children. Otherwise, it will render
  the fallback component. */
  const isAuthenticated = useMemo(
    () => authUser?.email && authUser?.emailVerified && !loading,
    [authUser?.email, authUser?.emailVerified, loading]
  )

  const [asideLeftCollapsed] = useStateWithStorage(true, ASIDE_VISIBILITY)

  /* The layoutConfig is memoized to only re-render when isAuthenticated changes. */
  const layoutConfig = {
    isAsideLeft: isAuthenticated,
    asideLeftCollapseVariant: 'full',
    asideLeftCollapsed,
    asideLeftOuter: false,
    isHeaderSticky: true
  }
  // TODO add language switcher on page
  const { sm } = useBreakpoint()

  return (
    <ThemeProvider theme={breakpoints}>
      {isAuthenticated ? (
        <LayoutSystemProvider {...layoutConfig}>
          <Layout
            header={
              <Header
                px={3}
                // we need this className to work properly with qonsoll layout component
                className="headerTop"
                {...styles.headerStyles}>
                <Container bounded {...styles.containerStyles}>
                  <Box ml={3} display="flex" alignItems="center">
                    <Title level={3} mr={3} color="var(--ql-color-white)">
                      {t(sm ? 'Tipps Editor App' : 'Editor')}
                    </Title>
                    <Logo />
                  </Box>
                  <Box display="flex" alignItems="center">
                    {/* <Icon {...styles.iconStyles} /> */}
                    <LanguageMenu color="var(--ql-color-white)" />
                    <AccountMenu
                      id={authUser?.uid}
                      avatar={user?.avatarUrl}
                      displayName={displayName}
                      email={authUser?.email}
                    />
                  </Box>
                </Container>
              </Header>
            }
            footer={<Footer />}
            asideLeft={<></>}>
            <Container bounded>{children}</Container>
          </Layout>
        </LayoutSystemProvider>
      ) : (
        <LayoutSystemProvider contentHorizontalPadding {...layoutConfig}>
          <Layout
            header={
              <LayoutHeader
                left={<Back />}
                center={
                  <Row h="center">
                    <Col cw="auto">
                      <Logo />
                    </Col>
                  </Row>
                }
                right={<LanguageMenu />}
              />
            }>
            <Container bounded height="100%">
              {children}
            </Container>
          </Layout>
        </LayoutSystemProvider>
      )}
    </ThemeProvider>
  )
}

BoilerplateLayout.propTypes = {
  children: PropTypes.element
}

export default BoilerplateLayout
