import { COLLECTIONS } from '__constants__'
import { useCollection } from 'services/api/firebase'
import { useMemo } from 'react'

const useGetUserNewsTips = (props) => {
  /* Query for getting collection reference */
  const query = useMemo(
    () => ({ ref: COLLECTIONS.NEWS_TIPS, ...props }),
    [props]
  )

  /* Getting collection data */
  return useCollection(query)
}

export default useGetUserNewsTips
