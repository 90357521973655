import { Table } from 'antd'
import styled from 'styled-components'

const StyledTable = styled(Table)`
  width: 100%;

  ::webkit-scrollbar-track {
    background-color: var(--table-sticky-header-bg);
  }
  padding-right: 4px;
  margin-right: -8px;
  .ant-table-content {
    min-height: 250px;
    // this property cover most of the cases according to canIuse data on 28.12.22
    // for all rest cases we have combined overflow
    overflow: overlay;
    max-height: ${({ tableOuterHeight }) =>
      `calc(100vh - ${tableOuterHeight || 550}px)`};
    position: relative;
    // we needthis height to cover cases when overflow: overlay doesn't work
    ::-webkit-scrollbar-button:single-button:vertical:decrement {
      height: var(--table-scroll-top-button-height);
    }
    .ant-table-thead {
      border-top-right-radius: 0;
      z-index: 1;
      position: sticky;
      top: 0;
      background-color: var(--table-sticky-header-bg);
    }
  }
  .ant-pagination {
    margin-bottom: 0 !important;
  }
`

export default StyledTable
