import { Button, Title } from '@qonsoll/react-design'
import {
  useActionsNewsTipAdvancedForm,
  useInitializeNewsTipAdvancedForm
} from './hooks'

import { Form } from 'antd'
import { KEYS } from '__constants__'
import { NewsTipSimpleForm } from 'domains/NewsTip/components'
import { NewsTipStatusSelect } from 'domains/NewsTipStatus/components'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const NewsTipAdvancedForm = (props) => {
  const { initialData, group, showTitle } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form } = useInitializeNewsTipAdvancedForm(initialData)
  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsNewsTipAdvancedForm({
    initialData,
    form
  })

  const isTipsFromGuest = !initialData?.newsTip?._createdBy

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('News tip')}
        </Title>
      )}
      <NewsTipSimpleForm showTitle={false} group={[...group, 'newsTip']} />
      <Form.Item name={[group, 'status']}>
        <NewsTipStatusSelect />
      </Form.Item>
    </>
  ) : (
    <Form
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('News tip')}
        </Title>
      )}
      <NewsTipSimpleForm
        group={['newsTip']}
        isTipsFromGuest={isTipsFromGuest}
      />
      <Form.Item name={['newsTip', 'status']}>
        <NewsTipStatusSelect />
      </Form.Item>
      <Button mr={2} htmlType="button" onClick={onReset} mb={4}>
        {t('Cancel')}
      </Button>
      <Button
        type="primary"
        loading={loading}
        onClick={() => form.submit()}
        disabled={isTipsFromGuest}>
        {t('Save')}
      </Button>
    </Form>
  )
}

NewsTipAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default NewsTipAdvancedForm
